import { Button, Checkbox, Col, Divider, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import ExportToExcel from "./ExportToExcel";

function DownloadButton({ data, options, fileName, disabled = false }) {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [columns, setColumns] = useState({});

  const plainOptions = options.map((i) => i.value);
  const [checkedList, setCheckedList] = useState(plainOptions);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const hasAddressSiteColumn = (data) =>
    Object.keys(data).some((key) => key === "address_site" || key === "user_address_site");

  const processedAddressSiteColumnMap = (columns) => {
    columns["zip"] = "郵遞區號";
    return columns;
  };

  const processedAddressSiteData = (data) =>
    data.map((item) => {
      const { address_site, user_address_site, ...rest } = item;
      const zip = (address_site || user_address_site || "").slice(0, 3);
      const site = (address_site || user_address_site || "").slice(3);

      return {
        zip,
        ...(address_site ? { address_site: site } : { user_address_site: site }),
        ...rest,
      };
    });

  const getColumns = (data, prefix) => {
    return [
      ...new Set(
        data
          .map((i) => Object.keys(i).filter((j) => j.startsWith(prefix)))
          .flat()
      ),
    ].reduce((obj, item) => Object.assign(obj, { [item]: item }), {});
  };

  useEffect(() => {
    setSelected(
      data.map((i) =>
        Object.keys(i)
          .filter(
            (j) =>
              checkedList.includes(j) ||
              (j.startsWith("extra_") && checkedList.includes("extra")) ||
              (j.startsWith("ecpay_") && checkedList.includes("ecpay"))
          )
          .reduce((obj, key) => {
            obj[key] = i[key];
            return obj;
          }, {})
      )
    );
  }, [checkedList, data]);

  useEffect(() => {
    const commonColumns = options
      .filter((i) => checkedList.includes(i.value))
      .reduce(
        (obj, item) => Object.assign(obj, { [item.value]: item.label }),
        {}
      );

    const extraColumns = checkedList.includes("extra")
      ? getColumns(data, "extra_")
      : {};
    const ecpayColumns = checkedList.includes("ecpay")
      ? getColumns(data, "ecpay_")
      : {};
    setColumns(Object.assign({}, commonColumns, extraColumns, ecpayColumns));
  }, [options, checkedList, data]);

  const checkboxes = options.map((i) => (
    <Col sm={24} md={12} lg={12} xl={12} key={i.value}>
      <Checkbox value={i.value} key={i.value}>
        {i.label}
      </Checkbox>
    </Col>
  ));

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const showModal = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} key="download" disabled={disabled}>
        下載
      </Button>
      <Modal
        title={`有${data.length}筆資料，請選擇要下載的欄位`}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="close" onClick={handleCancel}>
            取消
          </Button>,
          <ExportToExcel
            key="export"
            apiData={
              hasAddressSiteColumn(columns)
                ? processedAddressSiteData(selected)
                : selected
            }
            fileName={fileName}
            columnMap={
              hasAddressSiteColumn(columns)
                ? processedAddressSiteColumnMap(columns)
                : columns
            }
          />,
        ]}
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          全部
        </Checkbox>
        <Divider />
        <Checkbox.Group
          style={{ width: "100%" }}
          value={checkedList}
          onChange={onChange}
        >
          <Row>{checkboxes}</Row>
        </Checkbox.Group>
      </Modal>
    </>
  );
}

export default DownloadButton;
