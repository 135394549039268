import { Form, Input, Radio, Space } from "antd";

const ShowNameFormItem = ({ name }) => {
  return (
    <Form.Item
      label="是否同意公開真實姓名於捐款芳名錄"
      name={[name, "default"]}
      extra="依據財團法人法第25條第二款之規定，除捐款人表示反對，本會需主動公開捐贈者姓名及金額，若您勾匿名但未自行命名，將以「善心人士」名稱為您徵信"
      rules={[
        {
          required: true,
          message: "請選擇",
        },
      ]}
    >
      <Radio.Group>
        <Space direction="vertical">
          <Radio value={"本名"} key={"本名"}>
            本名
          </Radio>
          <Radio value={"匿名"}>
            匿名
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[name].default !== currentValues[name].default
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(name).default === "匿名" ? (
                  <Form.Item name={[name, "custom"]} noStyle>
                    <Input style={{ width: 150, marginLeft: 10 }} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};

export default ShowNameFormItem;
