import React, { useState } from "react";
import { useProgram } from "../../hooks/useProgram";
import EditButton from "../EditButton";
import ProgramForm from "./ProgramForm";

const EditProgramButton = ({ program }) => {
  const { updateProgram } = useProgram();
  const [visible, setVisible] = useState(false);
  const formId = "edit_program";

  const onFinish = (values) => {
    updateProgram.mutateAsync({ id: program.id, data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"學程"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateProgram.isLoading}
      children={
        <ProgramForm
          formId={formId}
          initialValues={program}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditProgramButton;
