import React, { useState } from "react";
import { useCampaign } from "../../hooks/useCampaign";
import EditButton from "../EditButton";
import CampaignForm from "./CampaignForm";

const EditCampaignButton = ({ campaign }) => {
  const { updateCampaign } = useCampaign();
  const [visible, setVisible] = useState(false);
  const formId = "edit_campaign";

  const onFinish = (values) => {
    const data = {
      ...values,
      period_options: values.period_options.map((i) => i["period"]).join("; "),
    };
    updateCampaign.mutateAsync({ id: campaign.id, data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"項目"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateCampaign.isLoading}
      children={
        <CampaignForm
          formId={formId}
          initialValues={{
            ...campaign,
            period_options: campaign.period_options
              ? campaign.period_options.split("; ").map((i) => {
                  return { period: parseInt(i) };
                })
              : [],
          }}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditCampaignButton;
