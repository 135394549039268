import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useUser = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const create = useMutation(({ data }) => api.User.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.User.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    }
  );

  return {
    createUser: create,
    updateUser: update,
  };
};
