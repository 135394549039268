const religionOptions = ["無", "佛教", "道教", "基督教", "天主教", "其他"];
const professionOptions = [
  "金融/保險",
  "房地產",
  "政府機關",
  "軍警",
  "教育/研究",
  "經商",
  "建築/營造",
  "製造/供應商",
  "資訊業",
  "服務業",
  "醫療",
  "法律",
  "流通/零售",
  "交通/旅遊",
  "娛樂/出版",
  "傳播/廣告",
  "藝術",
  "農漁牧",
  "家管",
  "學生",
  "非營利組織",
  "待業中",
  "其他",
  "神職人員",
];
const educationOptions = [
  "博士",
  "碩士",
  "大學專科",
  "高中職",
  "國中",
  "小學",
  "其他",
  "無",
];
const maritalStatusOptions = ["已婚", "未婚", "離婚", "喪偶"];
const lunchOptions = ["葷食", "素食（方便素）", "不需要"];
const identityOptions = ["新生", "補課", "複訓"];
const invoiceOptions = ["捐贈本機構", "一般發票", "統編發票"];

const officialType = ["身分證字號", "護照號碼", "統一編號", "居留證號"];

const status = {
  pending: "等待付款中",
  waiting_for_check: "待確認",
  get_info_success: "取號成功",
  get_info_fail: "取號失敗",
  pay_fail: "失敗",
  complete: "完成",
};

const sendInvoiceOptions = {
  e_annual: "電子申報（自然人憑證）",
  annual: "年度寄發",
  everytime: "按次寄發",
  no_need: "不需寄發",
};

const paymentType = {
  in_person: "In person",
  post: "Post",
  credit_card: "Credit",
  cvs: "CVS",
  web_atm: "WebATM",
  atm: "ATM",
};

const registrationSelectStatus = {
  pending: "等待付款中",
  complete: "完成",
};

export {
  educationOptions,
  identityOptions,
  invoiceOptions,
  lunchOptions,
  maritalStatusOptions,
  officialType,
  paymentType,
  professionOptions,
  registrationSelectStatus,
  religionOptions,
  sendInvoiceOptions,
  status,
};
