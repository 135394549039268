import { Space, Table } from "antd";
import { useOffice } from "../../hooks/useOffice";
import useOffices from "../../hooks/useOffices";
import DeleteButton from "../DeleteButton";
import EditOfficeButton from "./EditOfficeButton";

const OfficeTable = () => {
  const { data: offices, isLoading, isSuccess } = useOffices();
  const { deleteOffice } = useOffice();

  const columns = [
    { key: "id", dataIndex: "id", title: "開課單位編號" },
    { key: "name", dataIndex: "name", title: "開課單位名稱" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <EditOfficeButton office={record} />
          <DeleteButton
            title={`你確定要刪除「${record.name}」? 所有相關的課程，場次和報名紀錄都會一起刪除`}
            onDelete={() => {
              deleteOffice.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data =
    isSuccess &&
    offices.map((d, i) => {
      d.key = i;
      return d;
    });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={isLoading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default OfficeTable;
