import { Button, Col, Form, Row, Select, Space } from "antd";
import { useState } from "react";
import useCourses from "../../hooks/useCourses";

const { Option } = Select;

const SearchForm = ({ onFinish }) => {
  const { data: courses, isSuccess: isSuccessCourse } = useCourses();
  const [form] = Form.useForm();
  const [courseId, setCourseId] = useState(undefined);

  const onChange = (value) => {
    setCourseId(value);
  };

  return (
    <Form form={form} name="session_search" onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={8} key={"course"}>
          <Form.Item name={`course_id`} label={`課程名稱`}>
            <Select onChange={onChange}>
              {isSuccessCourse &&
                courses.map((i) => (
                  <Option value={i.id} key={i.id}>
                    {i.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={!courseId}>
              搜尋
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              清除
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
