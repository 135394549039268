import {
  HeartOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Space, Tag } from "antd";
import { useContext } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AuthContext } from "../components/AuthContext";
import Campaign from "./Campaign.jsx";
import Course from "./Course.jsx";
import Dashboard from "./Dashborad";
import Donation from "./Donation.jsx";
import Faq from "./Faq.jsx";
import Identity from "./Identity.jsx";
import Invoice from "./Invoice.jsx";
import Newsletter from "./Newsletter.jsx";
import Office from "./Office.jsx";
import Program from "./Program.jsx";
import Project from "./Project.jsx";
import Registration from "./Registration.jsx";
import Session from "./Session.jsx";
import Setting from "./Setting.jsx";
import User from "./User.jsx";

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;

function Home() {
  const { logout, token, email } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const active = location.pathname.split("/").splice(-1)[0];

  if (!token) {
    return <Redirect to="/login" />;
  }

  const handleClickLogout = () => {
    logout();
    history.push("/login");
  };

  return (
    <Layout>
      <Sider breakpoint="lg" collapsedWidth="0">
        <div className="my-4 text-center text-light">
          {process.env.REACT_APP_ORGANIZATION_NAME}
          <br />
          管理後台
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys="dashboard"
          selectedKeys={[active]}
        >
          <Menu.Item key="dashboard" icon={<HomeOutlined />}>
            <Link to="/dashboard">首頁</Link>
          </Menu.Item>
          <SubMenu key="sub_user" icon={<UserOutlined />} title="會員管理">
            <Menu.Item key="identity">
              <Link to="/identity">身份別</Link>
            </Menu.Item>
            <Menu.Item key="user">
              <Link to="/user">會員</Link>
            </Menu.Item>
            <Menu.Item key="newsletter">
              <Link to="/newsletter">月刊名單</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub_course" icon={<ReadOutlined />} title="課程管理">
            <Menu.Item key="office">
              <Link to="/office">開課單位</Link>
            </Menu.Item>
            <Menu.Item key="program">
              <Link to="/program">學程</Link>
            </Menu.Item>
            <Menu.Item key="course">
              <Link to="/course">課程</Link>
            </Menu.Item>
            <Menu.Item key="session">
              <Link to="/session">場次</Link>
            </Menu.Item>
            <Menu.Item key="registration">
              <Link to="/registration">報名紀錄</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub_donation" icon={<HeartOutlined />} title="捐款管理">
            <Menu.Item key="project">
              <Link to="/project">捐款類別</Link>
            </Menu.Item>
            <Menu.Item key="campaign">
              <Link to="/campaign">捐款項目</Link>
            </Menu.Item>
            <Menu.Item key="donation">
              <Link to="/donation">捐款紀錄</Link>
            </Menu.Item>
            <Menu.Item key="invoice">
              <Link to="/invoice">列印收據</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="faq" icon={<QuestionCircleOutlined />}>
            <Link to="/faq">問答設定</Link>
          </Menu.Item>
          <Menu.Item key="setting" icon={<SettingOutlined />}>
            <Link to="/setting">設定</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="bg-white d-flex justify-content-end align-items-center">
          <Space>
            <Tag color="blue">{email}</Tag>
            <Button onClick={handleClickLogout}>登出</Button>
          </Space>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#fff",
            minHeight: "100vh",
          }}
        >
          <Switch>
            <Route
              key="dashboard"
              path="/dashboard"
              render={(props) => <Dashboard {...props} />}
            ></Route>
            <Route path="/user" key="/user">
              <User />
            </Route>
            <Route
              key="identity"
              path="/identity"
              render={(props) => <Identity {...props} />}
            ></Route>
            <Route
              key="office"
              path="/office"
              render={(props) => <Office {...props} />}
            ></Route>
            <Route
              key="program"
              path="/program"
              render={(props) => <Program {...props} />}
            ></Route>
            <Route
              key="course"
              path="/course"
              render={(props) => <Course {...props} />}
            ></Route>
            <Route
              key="session"
              path="/session"
              render={(props) => <Session {...props} />}
            ></Route>
            <Route
              key="registration"
              path="/registration"
              render={(props) => <Registration {...props} />}
            ></Route>
            <Route
              key="project"
              path="/project"
              render={(props) => <Project {...props} />}
            ></Route>
            <Route
              key="campaign"
              path="/campaign"
              render={(props) => <Campaign {...props} />}
            ></Route>
            <Route
              key="faq"
              path="/faq"
              render={(props) => <Faq {...props} />}
            ></Route>
            <Route
              key="setting"
              path="/setting"
              render={(props) => <Setting {...props} />}
            ></Route>
            <Route
              key="donation"
              path="/donation"
              render={(props) => <Donation {...props} />}
            ></Route>
            <Route
              key="invoice"
              path="/invoice"
              render={(props) => <Invoice {...props} />}
            ></Route>
            <Route
              key="newsletter"
              path="/newsletter"
              render={(props) => <Newsletter {...props} />}
            ></Route>

            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Home;
