import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useIdentity = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const create = useMutation((data) => api.Identity.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("identities");
    },
  });

  const del = useMutation((id) => api.Identity.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("identities");
    },
  });

  return {
    createIdentity: create,
    deleteIdentity: del,
  };
};
