import { Form, Input, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import useCourses from "../../hooks/useCourses";
import usePrograms from "../../hooks/usePrograms";

const { TextArea } = Input;
const { Option } = Select;

const CourseForm = ({ onFinish, formId, initialValues }) => {
  const { data: courses, isSuccess: isSuccessCourse } = useCourses();
  const { data: programs, isSuccess: isSuccessProgram } = usePrograms();
  const [prerequisiteOptions, setPrerequisiteOptions] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isSuccessCourse) {
      setPrerequisiteOptions(
        courses
          .filter((i) => i.id !== initialValues.id)
          .map((i) => (
            <Option value={i.name} key={i.id}>
              {i.name}
            </Option>
          ))
      );
    }
  }, [isSuccessCourse, courses, initialValues.id]);

  return (
    <Form
      id={formId}
      form={form}
      preserve={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item label="學程" name="program_id">
        <Select>
          {isSuccessProgram &&
            programs.map((i) => (
              <Option value={i.id} key={i.name}>
                {i.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="課程名稱"
        name="name"
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="課程介紹（HTML）" name="info">
        <TextArea rows={4}></TextArea>
      </Form.Item>
      <Form.Item label="報名說明（HTML）" name="signup_info">
        <TextArea rows={4}></TextArea>
      </Form.Item>
      <Form.Item label="費用說明（HTML）" name="price_info">
        <TextArea rows={4}></TextArea>
      </Form.Item>
      <Form.Item label="先修課程" name="prerequisite">
        <Select mode="multiple" allowClear>
          {prerequisiteOptions}
        </Select>
      </Form.Item>
      <Form.Item label="是否在前台顯示" name="show" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default CourseForm;
