import React, { useState } from "react";
import { useCourse } from "../../hooks/useCourse";
import EditButton from "../EditButton";
import CourseForm from "./CourseForm";

const EditCourseButton = ({ course }) => {
  const { updateCourse } = useCourse();
  const [visible, setVisible] = useState(false);
  const formId = "edit_course";
  const initialValues = {
    ...course,
    prerequisite: course.prerequisite
      ? course.prerequisite.split("; ").map((i) => i)
      : [],
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      prerequisite: values.prerequisite.join("; "),
    };
    updateCourse.mutateAsync({ id: course.id, data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"課程"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateCourse.isLoading}
      children={
        <CourseForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditCourseButton;
