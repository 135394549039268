import { DatePicker, Descriptions, Divider, Form, Input, Switch } from "antd";

const { TextArea } = Input;

const UserLearningForm = ({ onFinish, formId, initialValues, learning }) => {
  return (
    <>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="課程場次">
          {learning.course_name}
          {learning.session_name}
        </Descriptions.Item>
        <Descriptions.Item label="會員">{learning.user_name}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form
        id={formId}
        preserve={false}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item label="是否完成課程" name="finished" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="結業日期" name="finished_date">
          <DatePicker />
        </Form.Item>
        <Form.Item label="備註" name="note">
          <TextArea />
        </Form.Item>
      </Form>
    </>
  );
};

export default UserLearningForm;
