import { Space, Table, Tag } from "antd";
import { useCampaign } from "../../hooks/useCampaign";
import useCampaigns from "../../hooks/useCampaigns";
import DeleteButton from "../DeleteButton";
import EditCampaignButton from "./EditCampaignButton";

const CampaignTable = () => {
  const { data: campaigns, isLoading, isSuccess } = useCampaigns();
  const { deleteCampaign } = useCampaign();

  const columns = [
    { key: "id", dataIndex: "id", title: "項目編號" },
    { key: "project_name", dataIndex: "project_name", title: "捐款類別" },
    { key: "name", dataIndex: "name", title: "項目名稱" },
    { key: "approval_id", dataIndex: "approval_id", title: "專案核准文號" },
    {
      key: "show",
      title: "在前台顯示",
      render: (text, record) =>
        record.show ? <Tag color="blue">顯示</Tag> : <Tag>不顯示</Tag>,
    },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <EditCampaignButton campaign={record} />
          <DeleteButton
            title={`你確定要刪除「${record.name}」? 所有相關的捐款紀錄都會一起刪除`}
            onDelete={() => {
              deleteCampaign.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data =
    isSuccess &&
    campaigns.map((d, i) => {
      d.key = i;
      return d;
    });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={isLoading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default CampaignTable;
