import React, { useState } from "react";
import { useRegistration } from "../../hooks/useRegistration";
import EditButton from "../EditButton";
import RegistrationForm from "./RegistrationForm";

const EditRegistrationButton = ({ registration }) => {
  const { updateRegistration } = useRegistration();
  const [visible, setVisible] = useState(false);
  const formId = `edit_registration_${registration.id}`;

  const initialValues = {
    ...registration,
  };

  const onFinish = (values) => {
    const session_id =
      values.new_session_id && values.new_session_id.length === 2
        ? values.new_session_id[1]
        : undefined;
    const data = session_id ? { ...values, session_id: session_id } : values;
    updateRegistration
      .mutateAsync({ id: registration.id, data: data })
      .then(() => {
        setVisible(false);
      });
  };

  return (
    <EditButton
      name={"報名紀錄"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateRegistration.isLoading}
      children={
        <RegistrationForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
          registration={registration}
        />
      }
    />
  );
};

export default EditRegistrationButton;
