import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useFaq = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const create = useMutation(({ data }) => api.Faq.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("faqs");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.Faq.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("faqs");
      },
    }
  );

  const del = useMutation((id) => api.Faq.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("faqs");
    },
  });

  return {
    createFaq: create,
    updateFaq: update,
    deleteFaq: del,
  };
};
