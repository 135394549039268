import React, { useState } from "react";
import { useLearning } from "../../hooks/useLearning";
import AddButton from "../AddButton";
import AddLearningForm from "./AddLearningForm";

const AddLearningButton = ({ user }) => {
  const { createLearning } = useLearning();
  const [visible, setVisible] = useState(false);
  const formId = "add_learning";

  const onFinish = (values) => {
    const data = {
      user_id: user.id,
      session_id: values.session_id,
      finished: values.finished,
    };
    createLearning.mutateAsync({ data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <AddButton
      name={"學習紀錄"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createLearning.isLoading}
      children={
        <AddLearningForm
          formId={formId}
          onFinish={onFinish}
          initialValues={{ finished: false }}
        />
      }
    />
  );
};

export default AddLearningButton;
