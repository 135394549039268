import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space } from "antd";

const { Option } = Select;

const ExtraFormItem = () => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        新增報名欄位（如需要用選項類別，請用,分隔不同選項）
      </div>
      <Form.List name="extra">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                <Form.Item
                  {...field}
                  label="名稱"
                  name={[field.name, "name"]}
                  fieldKey={[field.fieldKey, "name"]}
                  rules={[{ required: true, message: "請填寫" }]}
                >
                  <Input style={{ minWidth: 120 }} />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="類別"
                  name={[field.name, "type"]}
                  fieldKey={[field.fieldKey, "type"]}
                  rules={[{ required: true, message: "請選擇" }]}
                >
                  <Select style={{ minWidth: 80 }}>
                    <Option key={"input"} value={"input"}>
                      文字
                    </Option>
                    <Option key={"bool"} value={"bool"}>
                      是非
                    </Option>
                    <Option key={"option"} value={"option"}>
                      選項
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  label="必填"
                  name={[field.name, "require"]}
                  fieldKey={[field.fieldKey, "require"]}
                  rules={[{ required: true, message: "請選擇" }]}
                >
                  <Select style={{ minWidth: 80 }}>
                    <Option key={"true"} value={true}>
                      是
                    </Option>
                    <Option key={"false"} value={false}>
                      否
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  label="選項"
                  name={[field.name, "options"]}
                  fieldKey={[field.fieldKey, "options"]}
                >
                  <Input></Input>
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                新增欄位
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ExtraFormItem;
