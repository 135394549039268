import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useSession = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const create = useMutation((data) => api.Session.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("sessions");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.Session.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sessions");
      },
    }
  );

  const del = useMutation((id) => api.Session.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("sessions");
    },
  });

  return {
    createSession: create,
    updateSession: update,
    deleteSession: del,
  };
};
