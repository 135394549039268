import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Spin,
} from "antd";
import useCampaigns from "../../hooks/useCampaigns";
import InvoiceFormItem from "./InvoiceFormItem";
import ShowNameFormItem from "./ShowNameFormItem";

const { Option } = Select;
const { TextArea, Search } = Input;

const AddDonationForm = ({
  onFinish,
  formId,
  initialValues,
  form,
  setQuery,
  isLoading,
  isSuccess,
  users,
}) => {
  const { data: campaigns } = useCampaigns();

  const onSearch = (value) => {
    setQuery({ name: value });
    form.setFieldsValue({
      user_id: undefined,
    });
  };

  return (
    <>
      <Form
        id={formId}
        form={form}
        preserve={false}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item
          label="會員姓名（請勾選搜尋出的選項，如果未勾選就無法新增）"
          required={true}
        >
          <Search onSearch={onSearch} />
        </Form.Item>
        <Form.Item
          label="會員"
          noStyle={true}
          name="user_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {isLoading ? (
            <Spin size="small" />
          ) : isSuccess && users.length > 0 ? (
            <Radio.Group>
              <Space direction="vertical">
                {users.map((i) => (
                  <Radio value={i.id} key={i.id}>
                    {i.name} {i.email}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          ) : null}
        </Form.Item>
        <Form.Item
          label="捐款項目"
          style={{ marginTop: 20 }}
          name="campaign_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select showSearch>
            {campaigns.map((i) => (
              <Option value={i.id} key={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="金額"
          name="amount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label="繳款日期"
          name="offline_pay_date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="繳款帳號後五碼"
          name="offline_pay_account"
          required={true}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <InvoiceFormItem name={"send_invoice"} />
        <ShowNameFormItem name={"show_name"} />
        <Form.Item label="手動輸入勸募字號" name="campaign_official_id">
          <Input></Input>
        </Form.Item>
        <Form.Item label="管理員備註" name="manager_note">
          <TextArea rows={4}></TextArea>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddDonationForm;
