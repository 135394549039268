import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export default function useSessions() {
  const { token } = useContext(AuthContext);

  return useQuery(["session_options", token], () => api.Session.all(token));
}
