import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useProgram = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const create = useMutation((data) => api.Program.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("programs");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.Program.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("programs");
      },
    }
  );

  const del = useMutation((id) => api.Program.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("programs");
    },
  });

  return {
    createProgram: create,
    updateProgram: update,
    deleteProgram: del,
  };
};
