import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import useIdentities from "../../hooks/useIdentities";

const { Option } = Select;

const PriceFormItem = () => {
  const { data: identities, isSuccess } = useIdentities();

  return (
    <>
      <div style={{ marginBottom: 10 }}>價格</div>
      <Form.List name="prices">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                <Form.Item
                  {...field}
                  label="名稱"
                  name={[field.name, "name"]}
                  fieldKey={[field.fieldKey, "name"]}
                  rules={[{ required: true, message: "請填寫價格名稱" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="身份"
                  name={[field.name, "identity"]}
                  fieldKey={[field.fieldKey, "identity"]}
                  rules={[{ required: true, message: "請選擇身份限制" }]}
                >
                  <Select style={{ minWidth: 60 }}>
                    <Option key={"無"} value={"無"}>
                      無
                    </Option>
                    {isSuccess
                      ? identities.map((identity) => (
                          <Option key={identity.name} value={identity.name}>
                            {identity.name}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  label="金額"
                  name={[field.name, "amount"]}
                  fieldKey={[field.fieldKey, "amount"]}
                  rules={[{ required: true, message: "請填寫金額" }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item name={[field.name, "deadline"]} label={"截止日期"}>
                  <DatePicker />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                新增價格
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default PriceFormItem;
