import { Button, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import useCourses from "../../hooks/useCourses";
import { useSession } from "../../hooks/useSession";
import { dateFormat } from "../../utils";
import AddButton from "../AddButton";
import SessionForm from "./SessionForm";

const AddSessionButton = () => {
  const { data: courses, isSuccess } = useCourses();
  const { createSession } = useSession();
  const [visible, setVisible] = useState(false);
  const formId = "add_session";
  const initialValues = {
    prices: [],
    register_date_range: [moment().local(), moment().local()],
    address: {
      city: ["臺北市", "松山區"],
      street: "",
    },
    place: "",
    open: true,
  };

  const onFinish = (values) => {
    const prices = JSON.parse(JSON.stringify(values.prices));
    const data = {
      ...values,
      register_start_date: values.register_date_range[0].format(dateFormat),
      register_end_date: values.register_date_range[1].format(dateFormat),
      course_start_date: values.course_date_range[0].format(dateFormat),
      course_end_date: values.course_date_range[1].format(dateFormat),
      address_city: values.address.city ? values.address.city[0] : undefined,
      address_site: values.address.city ? values.address.city[1] : undefined,
      address_street: values.address.stree ? values.address.street : undefined,
      prices: prices.map((i) =>
        Object.assign(i, {
          deadline: i.deadline
            ? moment(i.deadline).format(dateFormat)
            : undefined,
        })
      ),
      extra: JSON.stringify(values.extra),
    };

    createSession.mutateAsync(data).then(() => {
      setVisible(false);
    });
  };

  return (
    <>
      {isSuccess && courses.length === 0 ? (
        <Tooltip title="請先到課程頁面新增課程">
          <Button disabled>新增</Button>
        </Tooltip>
      ) : (
        <AddButton
          name={"場次"}
          formId={formId}
          visible={visible}
          setVisible={setVisible}
          loading={createSession.isLoading}
          children={
            <SessionForm
              formId={formId}
              initialValues={initialValues}
              onFinish={onFinish}
            />
          }
        />
      )}
    </>
  );
};

export default AddSessionButton;
