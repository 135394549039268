import { Space, Table } from "antd";
import { useProgram } from "../../hooks/useProgram";
import usePrograms from "../../hooks/usePrograms";
import DeleteButton from "../DeleteButton";
import EditProgramButton from "./EditProgramButton";

const ProgramTable = () => {
  const { data: programs, isLoading, isSuccess } = usePrograms();
  const { deleteProgram } = useProgram();

  const columns = [
    { key: "id", dataIndex: "id", title: "學程編號" },
    { key: "name", dataIndex: "name", title: "學程名稱" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <EditProgramButton program={record} />
          <DeleteButton
            title={`你確定要刪除「${record.name}」? 所有相關的課程，場次和報名紀錄都會一起刪除`}
            onDelete={() => {
              deleteProgram.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data =
    isSuccess &&
    programs.map((d, i) => {
      d.key = i;
      return d;
    });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={isLoading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default ProgramTable;
