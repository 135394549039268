import { Space, Table } from "antd";
import useIdentities from "../../hooks/useIdentities";
import { useIdentity } from "../../hooks/useIdentity";
import DeleteButton from "../DeleteButton";

const IdentityTable = () => {
  const { data: identities, isLoading, isSuccess } = useIdentities();
  const { deleteIdentity } = useIdentity();

  const columns = [
    { key: "id", dataIndex: "id", title: "身份別編號" },
    { key: "name", dataIndex: "name", title: "身份別名稱" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <DeleteButton
            title={`你確定要刪除「${record.name}」? `}
            onDelete={() => {
              deleteIdentity.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data =
    isSuccess &&
    identities.map((d, i) => {
      d.key = i;
      return d;
    });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={isLoading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default IdentityTable;
