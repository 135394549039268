import { Space } from "antd";
import CourseTable from "../components/course/CourseTable";
import AddCourseButton from "../components/course/AddCourseButton";

const Course = () => {
  return (
    <>
      <Space>
        <AddCourseButton />
      </Space>
      <CourseTable />
    </>
  );
};

export default Course;
