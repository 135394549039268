import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useLearning = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const create = useMutation(({ data }) => api.Learning.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.Learning.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
      },
    }
  );

  const del = useMutation((id) => api.Learning.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
  });

  return {
    createLearning: create,
    updateLearning: update,
    deleteLearning: del,
  };
};
