import { Space, Table, Tag } from "antd";
import { useFaq } from "../../hooks/useFaq";
import useFaqs from "../../hooks/useFaqs";
import DeleteButton from "../DeleteButton";
import EditFaqButton from "./EditFaqButton";

const FaqTable = () => {
  const { data: faqs, isLoading, isSuccess } = useFaqs();
  const { deleteFaq } = useFaq();

  const columns = [
    { key: "id", dataIndex: "id", title: "編號" },
    { key: "question", dataIndex: "question", title: "問題" },
    { key: "answer", dataIndex: "answer", title: "答案" },
    {
      key: "show",
      title: "在前台顯示",
      render: (text, record) =>
        record.show ? <Tag color="blue">顯示</Tag> : <Tag>不顯示</Tag>,
    },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <EditFaqButton faq={record} />
          <DeleteButton
            title={`你確定要刪除問答編號「${record.id}」? `}
            onDelete={() => {
              deleteFaq.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data =
    isSuccess &&
    faqs.map((d, i) => {
      d.key = i;
      return d;
    });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={isLoading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default FaqTable;
