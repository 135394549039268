import React, { useState } from "react";
import { useOffice } from "../../hooks/useOffice";
import EditButton from "../EditButton";
import OfficeForm from "./OfficeForm";

const EditOfficeButton = ({ office }) => {
  const { updateOffice } = useOffice();
  const [visible, setVisible] = useState(false);
  const formId = "edit_office";

  const onFinish = (values) => {
    updateOffice.mutateAsync({ id: office.id, data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"開課單位"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateOffice.isLoading}
      children={
        <OfficeForm
          formId={formId}
          initialValues={office}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditOfficeButton;
