import { Space } from "antd";
import AddFaqButton from "../components/faq/AddFaqButton";
import FaqTable from "../components/faq/FaqTable";

const Faq = () => {
  return (
    <>
      <Space>
        <AddFaqButton />
      </Space>
      <FaqTable />
    </>
  );
};

export default Faq;
