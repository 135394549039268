import { Space, Table, Tag, Typography } from "antd";
import { useCourse } from "../../hooks/useCourse";
import useCourses from "../../hooks/useCourses";
import DeleteButton from "../DeleteButton";
import EditCourseButton from "./EditCourseButton";
import UploadImageButton from "./UploadImageButton";

const { Text } = Typography;

const CourseTable = () => {
  const { data: courses, isLoading, isSuccess } = useCourses();
  const { deleteCourse } = useCourse();

  const columns = [
    { key: "id", dataIndex: "id", title: "課程編號" },
    { key: "program_name", dataIndex: "program_name", title: "學程" },
    { key: "name", dataIndex: "name", title: "課程名稱" },
    {
      key: "show",
      title: "在前台顯示",
      render: (text, record) =>
        record.show ? <Tag color="blue">顯示</Tag> : <Tag>不顯示</Tag>,
    },
    { key: "price_info", dataIndex: "price_info", title: "費用說明" },
    {
      key: "prerequisite",
      dataIndex: "prerequisite",
      title: "先修課程",
      render: (text, record) => {
        return record.prerequisite && record.prerequisite !== "" ? (
          <Text>{record.prerequisite}</Text>
        ) : (
          <Text>無</Text>
        );
      },
    },
    {
      key: "image",
      title: "圖片",
      render: (text, record) => (
        <Space>
          <UploadImageButton course={record} />
        </Space>
      ),
    },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <EditCourseButton course={record} />
          <DeleteButton
            title={`你確定要刪除「${record.name}」? 所有相關的場次和報名紀錄都會一起刪除`}
            onDelete={() => {
              deleteCourse.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data =
    isSuccess &&
    courses.map((d, i) => {
      d.key = i;
      return d;
    });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={isLoading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default CourseTable;
