import { Divider } from "antd";
import { useEffect, useState } from "react";
import DownloadButton from "../components/DownloadButton";
import AddSessionButton from "../components/session/AddSessionButton";
import SearchForm from "../components/session/SearchForm";
import SessionTable from "../components/session/SessionTable";
import { sessionOptions } from "../downloadOptions";
import useSearchSessions from "../hooks/useSearchSessions";

const Session = () => {
  const [query, setQuery] = useState(undefined);
  const {
    data: sessions,
    isLoading,
    isSuccess,
    refetch,
  } = useSearchSessions(query);

  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const onFinish = (values) => {
    setQuery(values);
  };

  return (
    <>
      <AddSessionButton />
      <Divider />
      <SearchForm onFinish={onFinish} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <DownloadButton
          data={isSuccess ? sessions : []}
          disabled={isLoading}
          options={sessionOptions}
          fileName={"場次"}
        />
        <div>最多只顯示3000筆資料</div>
      </div>
      <SessionTable sessions={isSuccess ? sessions : []} loading={isLoading} />
    </>
  );
};

export default Session;
