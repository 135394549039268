import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export default function useSearchSessions(queries) {
  const { token } = useContext(AuthContext);

  let arg = [];

  if (queries) {
    if (queries["course_id"]) {
      arg.push(`course_id=${queries["course_id"]}`);
    }
  }

  const query_string = arg.join("&");

  return useQuery(
    ["sessions", token],
    () => api.Session.search({ query_string, token }),
    { enabled: !!queries }
  );
}
