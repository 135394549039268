import { Button, Col, Form, Input, Row, Space } from "antd";

const SearchForm = ({ onFinish }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} name="user_search" onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={8} key={"uuid"}>
          <Form.Item name={`uuid`} label={`會員編號`}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} key={"name"}>
          <Form.Item name={`name`} label={`姓名`}>
            <Input placeholder="陳小明" />
          </Form.Item>
        </Col>
        <Col span={8} key={"email"}>
          <Form.Item
            name={`email`}
            label={`Email`}
            rules={[
              {
                type: "email",
                message: "請填寫正確格式",
              },
            ]}
          >
            <Input placeholder="example@gmail.com" />
          </Form.Item>
        </Col>
        <Col span={8} key={"mobile"}>
          <Form.Item
            name={`mobile`}
            label={`手機`}
            rules={[
              {
                message: "請填寫正確格式",
                pattern: /09[0-9]{2}-[0-9]{3}-[0-9]{3}$/,
              },
            ]}
          >
            <Input placeholder="0900-000-000" />
          </Form.Item>
        </Col>
        <Col span={8} key={"tw_id"}>
          <Form.Item name={`official_id`} label={`證號`}>
            <Input placeholder="A000000000" />
          </Form.Item>
        </Col>
        <Col
          span={8}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            <Button type="primary" htmlType="submit">
              搜尋
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              清除
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
