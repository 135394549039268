import { Space, Table, Tag } from "antd";
import { useSession } from "../../hooks/useSession";
import DeleteButton from "../DeleteButton";
import CertificateButton from "./CertificateButton";
import EditSessionButton from "./EditSessionButton";
import PriceTags from "./PriceTags";

const SessionTable = ({ sessions, loading }) => {
  const { deleteSession } = useSession();
  const columns = [
    { key: "id", dataIndex: "id", title: "場次編號" },
    { key: "course", dataIndex: "course_name", title: "課程名稱" },
    { key: "name", dataIndex: "name", title: "場次名稱" },
    { key: "contact", dataIndex: "contact", title: "聯絡方式" },
    { key: "office_name", dataIndex: "office_name", title: "開課單位" },
    {
      key: "register_date",
      title: "開放報名日期",
      render: (text, record) => (
        <div>
          {record.register_start_date} ~ {record.register_end_date}
        </div>
      ),
    },
    { key: "course_date", dataIndex: "course_date", title: "課程日期" },
    {
      key: "prices",
      title: "價格",
      render: (text, record) => {
        return record.prices.length === 0 ? (
          <Tag>免費</Tag>
        ) : (
          <PriceTags data={record.prices} />
        );
      },
    },
    {
      key: "show",
      title: "在前台顯示",
      render: (text, record) =>
        record.show ? <Tag color="blue">顯示</Tag> : <Tag>不顯示</Tag>,
    },
    {
      key: "open",
      title: "開放報名",
      render: (text, record) =>
        record.open ? <Tag color="blue">開放</Tag> : <Tag>不開放</Tag>,
    },
    {
      key: "max_count",
      title: "報名人數限制",
      render: (text, record) =>
        record.max_count ? record.max_count : "無限制",
    },
    {
      key: "count",
      title: "目前報名人數",
      dataIndex: "count",
    },
    {
      key: "show_count",
      title: "是否顯示目前報名人數",
      render: (text, record) =>
        record.show_count ? <Tag color="blue">顯示</Tag> : <Tag>不顯示</Tag>,
    },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          {record.has_certificate ? (
            <CertificateButton session={record} />
          ) : null}
          <EditSessionButton session={record} />
          <DeleteButton
            title={`你確定要刪除「${record.name}」? 所有相關的報名紀錄都會一起刪除`}
            onDelete={() => {
              deleteSession.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data = sessions.map((d, i) => {
    d.key = i;
    return d;
  });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={loading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default SessionTable;
