import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export default function useNewsletters() {
  const { token } = useContext(AuthContext);

  return useQuery(["newsletter", token], () =>
    api.User.getNewsletterList(token)
  );
}
