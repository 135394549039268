import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Space } from "antd";

const PeriodOptionFormItem = () => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>定期定額期數選項</div>
      <Form.List
        name="period_options"
        rules={[
          {
            validator: async (_, period_options) => {
              if (!period_options || period_options.length < 1) {
                return Promise.reject(new Error("請至少填寫一個選項"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                <Form.Item
                  {...field}
                  label="期數"
                  name={[field.name, "period"]}
                  fieldKey={[field.fieldKey, "period"]}
                  rules={[{ required: true, message: "請填寫期數" }]}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.ErrorList errors={errors} />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                新增選項
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default PeriodOptionFormItem;
