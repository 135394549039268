import { Table } from "antd";
import ExportToExcel from "../components/ExportToExcel";
import useNewsletter from "../hooks/useNewsletter";

const Newsletter = () => {
  const columns = [
    { key: "uuid", dataIndex: "uuid", title: "會員編號" },
    { key: "name", dataIndex: "name", title: "姓名" },
    { key: "email", dataIndex: "email", title: "帳號" },
  ];

  const { data, isSuccess, isLoading } = useNewsletter();
  const fileName = "月刊名單";

  const downloadColumns = columns.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: item.title }),
    {}
  );

  return (
    <>
      {isSuccess ? (
        <ExportToExcel
          key="export"
          disabled={data.length === 0}
          apiData={data.map((i) =>
            (({ uuid, name, email }) => ({ uuid, name, email }))(i)
          )}
          fileName={fileName}
          columnMap={downloadColumns}
        />
      ) : null}
      <Table
        columns={columns}
        dataSource={data}
        className="mt-3"
        loading={isLoading}
      ></Table>
    </>
  );
};

export default Newsletter;
