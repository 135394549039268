import { Divider, Space, Table, Tag, Typography } from "antd";
import { useLearning } from "../../hooks/useLearning";
import { getSessionFilterOptions } from "../../utils";
import DeleteButton from "../DeleteButton";
import AddLearningButton from "./AddLearningButton";
import EditUserLearningButton from "./EditUserLearningButton";

const { Text } = Typography;

const UserLearningTable = ({ user, learnings }) => {
  const { deleteLearning } = useLearning();
  const data = learnings.map((d, i) => {
    d.key = i;
    return d;
  });

  const columns = [
    { key: "id", dataIndex: "id", title: "No" },
    {
      key: "session",
      title: "課程場次",
      render: (text, record) => (
        <Text>
          {record.course_name}
          {record.session_name}
        </Text>
      ),
      filters: getSessionFilterOptions(data),
      onFilter: (value, record) =>
        record.course_name + record.session_name === value,
    },
    {
      key: "course_start_date",
      title: "課程開始日期",
      dataIndex: "course_start_date",
    },
    {
      key: "course_end_date",
      title: "課程結束日期",
      dataIndex: "course_end_date",
    },
    {
      key: "finished",
      title: "課程狀態",
      render: (text, record) => (
        <Tag color={record.finished ? "green" : "blue"}>
          {record.finished ? "完成" : "還未完成"}
        </Tag>
      ),
    },
    {
      key: "finished_date",
      title: "結業日期",
      dataIndex: "finished_date",
    },
    {
      key: "note",
      title: "備註",
      dataIndex: "note",
    },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <EditUserLearningButton learning={record} />
          <DeleteButton
            title={`你確定要刪除學習記錄「${
              record.course_name + record.session_name
            }」?`}
            onDelete={() => {
              deleteLearning.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <AddLearningButton user={user} />
      <Divider></Divider>
      <Table
        columns={columns}
        dataSource={data}
        className="mt-3"
        scroll={{ x: 536 }}
      ></Table>
    </>
  );
};

export default UserLearningTable;
