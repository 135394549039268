import React, { useState } from "react";
import { useDonation } from "../../hooks/useDonation";
import EditButton from "../EditButton";
import DonationForm from "./DonationForm";

const EditDonationButton = ({ donation }) => {
  const { updateDonation } = useDonation();
  const [visible, setVisible] = useState(false);
  const formId = `edit_donation_${donation.id}`;

  const initialValues = {
    ...donation,
  };

  const onFinish = (values) => {
    updateDonation.mutateAsync({ id: donation.id, data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"捐款紀錄"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateDonation.isLoading}
      children={
        <DonationForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
          donation={donation}
        />
      }
    />
  );
};

export default EditDonationButton;
