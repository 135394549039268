import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

const encode = encodeURIComponent;

export default function useSearchUsers(queries) {
  const { token } = useContext(AuthContext);

  let arg = [];

  if (queries) {
    if (queries["uuid"]) {
      arg.push(`uuid=${queries["uuid"]}`);
    }
    if (queries["email"]) {
      arg.push(`email=${encode(queries["email"])}`);
    }
    if (queries["name"]) {
      arg.push(`name=${encode(queries["name"])}`);
    }
    if (queries["mobile"]) {
      arg.push(`mobile=${encode(queries["mobile"])}`);
    }
    if (queries["official_id"]) {
      arg.push(`official_id=${encode(queries["official_id"])}`);
    }
  }

  const query_string = arg.join("&");

  return useQuery(
    ["users", token],
    () => api.User.search({ query_string, token }),
    { enabled: !!queries }
  );
}
