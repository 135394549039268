import { Form } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { sendInvoiceOptions, status } from "../../constants";
import { useDonation } from "../../hooks/useDonation";
import useSearchUsers from "../../hooks/useSearchUsers";
import { dateFormat, dateTimeFormat } from "../../utils";
import AddButton from "../AddButton";
import AddDonationForm from "./AddDonationForm";

const AddDonationButton = () => {
  const [form] = Form.useForm();
  const { createDonation } = useDonation();
  const [visible, setVisible] = useState(false);
  const formId = "add_donation";
  const [query, setQuery] = useState(undefined);
  const { data: users, isSuccess, isLoading, refetch } = useSearchUsers(query);

  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const getShowName = (showName) =>
    showName.default === "本名" ? showName.default : showName.custom;

  const getSendInvoiceAddress = (sendInvoice) =>
    sendInvoice.choice === sendInvoiceOptions.e_annual ||
    sendInvoice.choice === sendInvoiceOptions.e_annual
      ? ""
      : sendInvoice.address;

  const onFinish = (values) => {
    const data = {
      ...values,
      create_date: moment().format(dateTimeFormat),
      offline_pay_date: moment(values.offline_pay_date).format(dateFormat),
      send_invoice: values.send_invoice.choice,
      send_invoice_address: getSendInvoiceAddress(values.send_invoice),
      show_name: getShowName(values.show_name),
      period: 1,
    };
    createDonation.mutateAsync({ data: data }).then(() => {
      setVisible(false);
      setQuery({ name: undefined });
      form.resetFields();
    });
  };

  return (
    <AddButton
      name={"捐款紀錄"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createDonation.isLoading}
      children={
        <AddDonationForm
          formId={formId}
          form={form}
          onFinish={onFinish}
          setQuery={setQuery}
          isLoading={isLoading}
          isSuccess={isSuccess}
          users={users}
          initialValues={{
            status: status.complete,
            send_invoice: { choice: sendInvoiceOptions.e_annual, address: "" },
            show_name: { default: "本名", custom: "善心人士" },
            manager_note: "",
          }}
        />
      }
    />
  );
};

export default AddDonationButton;
