import { Divider } from "antd";
import { useEffect, useState } from "react";
import AddDonationButton from "../components/donation/AddDonationButton";
import DonationTable from "../components/donation/DonationTable";
import SearchForm from "../components/donation/SearchForm";
import DownloadButton from "../components/DownloadButton";
import { donationOptions } from "../downloadOptions";
import useSearchDonations from "../hooks/useSearchDonations";

const Donation = () => {
  const [query, setQuery] = useState(undefined);
  const { data, refetch, isSuccess, isLoading } = useSearchDonations(query);

  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const onFinish = (values) => {
    setQuery(values);
  };

  return (
    <>
      <AddDonationButton />
      <Divider />
      <SearchForm onFinish={onFinish} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <DownloadButton
          data={isSuccess ? data : []}
          disabled={isLoading}
          options={donationOptions}
          fileName={"捐款紀錄"}
        />
        <div>最多只顯示3000筆資料</div>
      </div>
      <DonationTable donations={isSuccess ? data : []} loading={isLoading} />
    </>
  );
};

export default Donation;
