import React, { useState } from "react";
import { useCourse } from "../../hooks/useCourse";
import AddButton from "../AddButton";
import CourseForm from "./CourseForm";

const AddCourseButton = () => {
  const { createCourse } = useCourse();
  const [visible, setVisible] = useState(false);
  const formId = "add_course";
  const initialValues = {
    info: "",
    signup_info: "",
    signup_type: "個人",
    show: false,
    prerequisite: [],
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      prerequisite: values.prerequisite.join("; "),
    };
    console.log(data);
    createCourse.mutateAsync(data).then(() => {
      setVisible(false);
    });
  };

  return (
    <>
      <AddButton
        name={"課程"}
        formId={formId}
        visible={visible}
        setVisible={setVisible}
        loading={createCourse.isLoading}
        children={
          <CourseForm
            formId={formId}
            initialValues={initialValues}
            onFinish={onFinish}
          />
        }
      />
    </>
  );
};

export default AddCourseButton;
