import { Form, Input } from "antd";
import generator from "generate-password";

const AddUserForm = ({ onFinish, formId }) => {
  return (
    <Form
      id={formId}
      preserve={false}
      layout={"vertical"}
      initialValues={{
        password: generator.generate({
          length: 10,
          numbers: true,
          symbols: true,
          strict: true,
        }),
      }}
      onFinish={onFinish}
    >
      <Form.Item
        label="帳號（email）"
        name="email"
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="密碼"
        name="password"
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="姓名" name="name">
        <Input></Input>
      </Form.Item>
    </Form>
  );
};

export default AddUserForm;
