import React, { useState } from "react";
import { useOffice } from "../../hooks/useOffice";
import AddButton from "../AddButton";
import OfficeForm from "./OfficeForm";

const AddOfficeButton = () => {
  const { createOffice } = useOffice();
  const [visible, setVisible] = useState(false);
  const formId = "add_office";
  const initialValues = { name: undefined };

  const onFinish = (values) => {
    createOffice.mutateAsync(values).then(() => {
      setVisible(false);
    });
  };

  return (
    <AddButton
      name={"開課單位"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createOffice.isLoading}
      children={
        <OfficeForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default AddOfficeButton;
