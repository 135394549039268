import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useRegistration = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const update = useMutation(
    ({ id, data }) => api.Registration.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("registrations");
        queryClient.invalidateQueries("download_registrations");
        queryClient.invalidateQueries("user");
      },
    }
  );

  return {
    updateRegistration: update,
  };
};
