import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { useContext } from "react";
import { AuthContext } from "../../components/AuthContext";

const API_ROOT = process.env.REACT_APP_ARK_API_ROOT;

const UploadButton = ({ session }) => {
  const { token } = useContext(AuthContext);
  const props = {
    action: `${API_ROOT}/v1/session/${session.id}/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },

    defaultFileList: session.certificate_name
      ? [
          {
            uid: "1",
            name: session.certificate_name.split("__").slice(2),
            status: "done",
          },
        ]
      : [],

    showUploadList: {
      showRemoveIcon: false,
    },

    onChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }

      if (info.file.status === "done") {
        message.success(`${info.file.name} 上傳成功`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上傳失敗`);
      }
    },
  };

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />}>上傳結業證書範本</Button>
    </Upload>
  );
};

export default UploadButton;
