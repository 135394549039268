import { Space } from "antd";
import AddCampaignButton from "../components/campaign/AddCampaignButton";
import CampaignTable from "../components/campaign/CampaignTable";

const Campaign = () => {
  return (
    <>
      <Space>
        <AddCampaignButton />
      </Space>
      <CampaignTable />
    </>
  );
};

export default Campaign;
