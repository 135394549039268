import { Button, DatePicker, Form, Space, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import useInvoices from "../../hooks/useInvoices";
import { dateFormat, dateTimeFormat } from "../../utils";
import DownloadInvoiceButton from "./DownloadInvoiceButton";

const { RangePicker } = DatePicker;

const InvoiceTab = () => {
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [url, setUrl] = useState("");
  const { data, refetch, isLoading } = useInvoices({ startDate, endDate });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const API_ROOT = process.env.REACT_APP_ARK_API_ROOT;
  const base_url = `${API_ROOT}/v1/user/donation/download/invoices?`;

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    const d = data.filter((i) => selectedRowKeys.includes(i.key));
    const q = d.map((i) => `ids=${i.id}`).join("&");
    setUrl(base_url + q);
  };

  const onDownload = () => {
    setSelectedRowKeys([]);
    setUrl("");
  };

  const onFinish = (values) => {
    setStartDate(values.range[0].format(dateFormat));
    setEndDate(values.range[1].format(dateFormat));
    refetch();
  };

  const columns = [
    { key: "create_date", dataIndex: "create_date", title: "捐款時間" },
    { key: "user_name", dataIndex: "user_name", title: "會員" },
    { key: "send_invoice", dataIndex: "send_invoice", title: "收據寄發" },
    {
      key: "amount",
      title: "金額",
      dataIndex: "amount",
    },
    {
      key: "printed",
      title: "是否被列印過",
      render: (text, record) =>
        record.printed ? <div>是</div> : <div>否</div>,
    },
  ];

  return (
    <>
      <Form name="every_time" layout={"inline"} onFinish={onFinish}>
        <Form.Item
          name="range"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <RangePicker
            picker="range"
            ranges={{
              這個月: [moment().startOf("month"), moment().endOf("month")],
            }}
          />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit">
            搜尋
          </Button>
        </Space>
      </Form>
      <div style={{ marginTop: 15 }}>
        <DownloadInvoiceButton
          disabled={!(data && data.length > 0)}
          url={url}
          onClick={onDownload}
        />
      </div>
      <Table
        columns={columns}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }}
        dataSource={
          data &&
          data.map((d, i) => {
            d.key = i;
            d.create_date = moment(d.create_date).format(dateTimeFormat);
            return d;
          })
        }
        className="mt-3"
        loading={isLoading}
      ></Table>
    </>
  );
};

export default InvoiceTab;
