import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useOrganization = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const query = useQuery(["organization", token], () =>
    api.Organization.get(token)
  );

  const update = useMutation(
    ({ data }) => api.Organization.update(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organization");
      },
    }
  );

  return {
    isLoading: query.isLoading,
    organization: query.isSuccess ? query.data : undefined,
    updateOrganization: update,
  };
};
