import { Descriptions, Divider, Form, Input } from "antd";
import { sendInvoiceOptions } from "../../constants";

const { TextArea } = Input;

const DonationForm = ({ onFinish, formId, initialValues, donation }) => {
  const ecpay =
    typeof donation.ecpay === "string"
      ? JSON.parse(donation.ecpay)
      : donation.ecpay;
  return (
    <>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="捐款日期">
          {donation.create_date}
        </Descriptions.Item>
        <Descriptions.Item label="類別">
          {donation.project_name}
        </Descriptions.Item>
        <Descriptions.Item label="項目">
          {donation.campaign_name}
        </Descriptions.Item>
        <Descriptions.Item label="專案核准文號">
          {donation.campaign_approval_id}
        </Descriptions.Item>
        {donation.user_id ? (
          <>
            <Descriptions.Item label="會員帳號">
              {donation.user_email}
            </Descriptions.Item>
            <Descriptions.Item label="會員">
              {donation.user_name}
            </Descriptions.Item>
          </>
        ) : (
          <>
            <Descriptions.Item label="email">
              {donation.email}
            </Descriptions.Item>
            <Descriptions.Item label="姓名">{donation.name}</Descriptions.Item>
            <Descriptions.Item label="電話">
              {donation.mobile}
            </Descriptions.Item>
            <Descriptions.Item label="證號">
              {donation.official_id}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="金額">{donation.amount}</Descriptions.Item>
        <Descriptions.Item label="捐款類別">
          {donation.donation_type}
        </Descriptions.Item>
        {donation.donation_type === "定期" ? (
          <>
            <Descriptions.Item label="捐款期數">
              {donation.period}
            </Descriptions.Item>
            <Descriptions.Item label="扣款成功次數">
              {donation.success_times}
            </Descriptions.Item>
          </>
        ) : null}
        <Descriptions.Item label="收據寄發方式">
          {donation.send_invoice}
        </Descriptions.Item>
        {donation.send_invoice !== sendInvoiceOptions[2] ? (
          <Descriptions.Item label="收據寄發地址">
            {donation.send_invoice_address}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label="芳名錄">
          {donation.show_name}
        </Descriptions.Item>
        <Descriptions.Item label="備註">{donation.note}</Descriptions.Item>
        <Descriptions.Item label="狀態">{donation.status}</Descriptions.Item>
        <Descriptions.Item label="繳款方式">
          {donation.payment_type}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      {ecpay ? (
        <Descriptions column={1} bordered>
          <Descriptions.Item label="線上交易編號">
            {ecpay.merchant_trade_no}
          </Descriptions.Item>
          <Descriptions.Item label="綠界回傳交易編號">
            {ecpay.trade_no}
          </Descriptions.Item>
          <Descriptions.Item label="綠界交易日期">
            {ecpay.merchant_trade_date}
          </Descriptions.Item>
          <Descriptions.Item label="綠界交易金額">
            {ecpay.trade_amt}
          </Descriptions.Item>
          <Descriptions.Item label="綠界回傳交易訊息">
            {ecpay.rtn_msg}
          </Descriptions.Item>
          <Descriptions.Item label="綠界超商或ATM繳費截止日">
            {ecpay.expire_date}
          </Descriptions.Item>
          <Descriptions.Item label="綠界超商繳費號碼">
            {ecpay.payment_no}
          </Descriptions.Item>
          <Descriptions.Item label="綠界ATM繳費銀行代碼">
            {ecpay.bank_code}
          </Descriptions.Item>
          <Descriptions.Item label="綠界ATM繳費號碼">
            {ecpay.v_account}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Descriptions column={1} bordered>
          <Descriptions.Item label="轉帳繳款日期">
            {donation.offline_pay_date}
          </Descriptions.Item>
          <Descriptions.Item label="轉帳繳款帳號後五碼">
            {donation.offline_pay_account}
          </Descriptions.Item>
        </Descriptions>
      )}
      <Divider />
      <Form
        id={formId}
        preserve={false}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item label="收據編號" name="invoice_id">
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item label="手動輸入勸募字號" name="campaign_official_id">
          <Input></Input>
        </Form.Item>
        <Form.Item label="管理員備註" name="manager_note">
          <TextArea rows={4}></TextArea>
        </Form.Item>
      </Form>
    </>
  );
};

export default DonationForm;
