import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";

const API_ROOT = process.env.REACT_APP_ARK_API_ROOT;

const UploadImageButton = ({ course }) => {
  const { token } = useContext(AuthContext);
  const props = {
    action: `${API_ROOT}/v1/course/${course.id}/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },

    defaultFileList: course.image_url
      ? [
          {
            uid: "1",
            name: course.image_url.split("__").slice(2),
            status: "done",
          },
        ]
      : [],

    showUploadList: {
      showRemoveIcon: false,
    },

    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} 上傳成功`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上傳失敗`);
      }
    },
  };

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />}></Button>
    </Upload>
  );
};

export default UploadImageButton;