import React, { useState } from "react";
import { useProject } from "../../hooks/useProject";
import EditButton from "../EditButton";
import ProjectForm from "./ProjectForm";

const EditProjectButton = ({ project }) => {
  const { updateProject } = useProject();
  const [visible, setVisible] = useState(false);
  const formId = "edit_project";

  const onFinish = (values) => {
    updateProject.mutateAsync({ id: project.id, data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"類別"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateProject.isLoading}
      children={
        <ProjectForm
          formId={formId}
          initialValues={project}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditProjectButton;
