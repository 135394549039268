import React, { useState } from "react";
import { useProject } from "../../hooks/useProject";
import AddButton from "../AddButton";
import ProjectForm from "./ProjectForm";

const AddProjectButton = () => {
  const { createProject } = useProject();
  const [visible, setVisible] = useState(false);
  const formId = "add_project";
  const initialValues = {
    info: "",
    show: false,
  };

  const onFinish = (values) => {
    createProject.mutateAsync(values).then(() => {
      setVisible(false);
    });
  };

  return (
    <AddButton
      name={"類別"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createProject.isLoading}
      children={
        <ProjectForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default AddProjectButton;
