import { Form, Select, Switch } from "antd";
import { useState } from "react";
import useCourses from "../../hooks/useCourses";
import useSessions from "../../hooks/useSessions";

const { Option } = Select;

const AddLearningForm = ({ onFinish, formId, initialValues }) => {
  const { data: sessions, isSuccess: isSuccessSession } = useSessions();
  const { data: courses, isSuccess: isSuccessCourse } = useCourses();
  const [courseId, setCourseId] = useState(undefined);

  const onSelectCourse = (value) => {
    setCourseId(value);
  };

  return (
    <Form
      id={formId}
      preserve={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="課程"
        name="course_id"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select onSelect={onSelectCourse}>
          {isSuccessCourse
            ? courses.map((i) => <Option value={i.id}>{i.name}</Option>)
            : null}
        </Select>
      </Form.Item>
      <Form.Item
        label="場次"
        name="session_id"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select>
          {isSuccessSession && courseId
            ? sessions
                .filter((i) => i.course_id === courseId)
                .map((i) => <Option value={i.id}>{i.name}</Option>)
            : null}
        </Select>
      </Form.Item>
      <Form.Item label="是否已完成" name="finished" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default AddLearningForm;
