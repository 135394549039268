import axios from "axios";

const API_ROOT = process.env.REACT_APP_ARK_API_ROOT;
const TOKEN_KEY = "jwt";

function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

axios.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 401 || status === 403) {
        window.localStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.location.href = `/login`;
      }
    }
    return Promise.reject(error);
  }
);

const requests = {
  del: (url, token) =>
    axios
      .delete(`${API_ROOT}${url}`, authHeaders(token))
      .then((res) => res.data),
  get: (url, token) =>
    axios.get(`${API_ROOT}${url}`, authHeaders(token)).then((res) => res.data),
  put: (url, body, token) =>
    axios
      .put(`${API_ROOT}${url}`, body, authHeaders(token))
      .then((res) => res.data),
  post: (url, body, token) =>
    axios
      .post(`${API_ROOT}${url}`, body, authHeaders(token))
      .then((res) => res.data),
};

const Login = {
  login: (username, password) => {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return axios.post(`${API_ROOT}/v1/login/access-token`, formData);
  },
};

const User = {
  get: (uuid, token) => requests.get(`/v1/user/${uuid}`, token),
  create: (data, token) => requests.post(`/v1/user`, data, token),
  search: ({ query_string, token }) =>
    requests.get(`/v1/user/search?${query_string}`, token),
  update: (id, data, token) => requests.put(`/v1/user/${id}`, data, token),
  getNewsletterList: (token) => requests.get(`/v1/user/newsletter`, token),
};

const Office = {
  all: (token) => requests.get(`/v1/office`, token),
  create: (data, token) => requests.post(`/v1/office`, data, token),
  update: (id, data, token) => requests.put(`/v1/office/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/office/${id}`, token),
};

const Identity = {
  all: (token) => requests.get(`/v1/identity`, token),
  create: (data, token) => requests.post(`/v1/identity`, data, token),
  delete: (id, token) => requests.del(`/v1/identity/${id}`, token),
};

const Program = {
  all: (token) => requests.get(`/v1/program`, token),
  create: (data, token) => requests.post(`/v1/program`, data, token),
  update: (id, data, token) => requests.put(`/v1/program/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/program/${id}`, token),
};

const Course = {
  all: (token) => requests.get(`/v1/course`, token),
  create: (data, token) => requests.post(`/v1/course`, data, token),
  update: (id, data, token) => requests.put(`/v1/course/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/course/${id}`, token),
};

const Session = {
  all: (token) => requests.get(`/v1/session/`, token),
  search: ({ query_string, token }) =>
    requests.get(`/v1/session/search?${query_string}`, token),
  create: (data, token) => requests.post(`/v1/session`, data, token),
  update: (id, data, token) => requests.put(`/v1/session/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/session/${id}`, token),
};

const Registration = {
  search: ({ query_string, token }) =>
    requests.get(`/v1/user/registration/search?${query_string}`, token),
  update: (id, data, token) =>
    requests.put(`/v1/user/registration/${id}`, data, token),
};

const Learning = {
  create: (data, token) => requests.post(`/v1/user/learning/`, data, token),
  update: (id, data, token) =>
    requests.put(`/v1/user/learning/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/user/learning/${id}`, token),
};

const Project = {
  all: (token) => requests.get(`/v1/project`, token),
  create: (data, token) => requests.post(`/v1/project`, data, token),
  update: (id, data, token) => requests.put(`/v1/project/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/project/${id}`, token),
};

const Campaign = {
  all: (token) => requests.get(`/v1/campaign`, token),
  create: (data, token) => requests.post(`/v1/campaign`, data, token),
  update: (id, data, token) => requests.put(`/v1/campaign/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/campaign/${id}`, token),
};

const Donation = {
  search: ({ query_string, token }) =>
    requests.get(`/v1/user/donation/search?${query_string}`, token),
  getAnnualInvoices: ({ year, sendInvoiceType, token }) =>
    requests.get(
      `/v1/user/donation/annual_invoices?year=${year}&send_invoice_type=${sendInvoiceType}`,
      token
    ),
  getInvoices: ({ startDate, endDate, token }) =>
    requests.get(
      `/v1/user/donation/invoices?create_start_date=${startDate}&create_end_date=${endDate}`,
      token
    ),
  create: (data, token) =>
    requests.post(`/v1/user/donation/post/`, data, token),
  update: (id, data, token) =>
    requests.put(`/v1/user/donation/${id}`, data, token),
};

const Organization = {
  get: (token) => requests.get(`/v1/organization`, token),
  update: (data, token) => requests.put(`/v1/organization`, data, token),
};

const Faq = {
  all: (token) => requests.get(`/v1/faq`, token),
  create: (data, token) => requests.post(`/v1/faq`, data, token),
  update: (id, data, token) => requests.put(`/v1/faq/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/faq/${id}`, token),
};

const Statistic = {
  get: (token) => requests.get(`/v1/statistic`, token),
};

const api = {
  Login,
  User,
  Office,
  Program,
  Course,
  Session,
  Registration,
  Learning,
  Project,
  Campaign,
  Donation,
  Identity,
  Organization,
  Faq,
  Statistic,
};

export default api;
