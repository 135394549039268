import moment from "moment";
import React, { useState } from "react";
import { useSession } from "../../hooks/useSession";
import { dateFormat } from "../../utils";
import EditButton from "../EditButton";
import SessionForm from "./SessionForm";

const EditSessionButton = ({ session }) => {
  const { updateSession } = useSession();
  const [visible, setVisible] = useState(false);
  const formId = "edit_session";
  const initialValues = {
    ...session,
    register_date_range: [
      moment(session.register_start_date),
      moment(session.register_end_date),
    ],
    course_date_range: [
      session.course_start_date ? moment(session.course_start_date) : undefined,
      session.course_end_date ? moment(session.course_end_date) : undefined,
    ],
    address: {
      city: [session.address_city, session.address_site],
      street: session.address_street,
    },
    extra: JSON.parse(session.extra),
    prices: session.prices.map((i) =>
      Object.assign(i, {
        deadline: i.deadline ? moment(i.deadline) : undefined,
      })
    ),
  };

  const onFinish = (values) => {
    const prices = JSON.parse(JSON.stringify(values.prices));
    const data = {
      ...values,
      register_start_date: values.register_date_range[0].format(dateFormat),
      register_end_date: values.register_date_range[1].format(dateFormat),
      course_start_date: values.course_date_range[0].format(dateFormat),
      course_end_date: values.course_date_range[1].format(dateFormat),
      address_city: values.address.city ? values.address.city[0] : undefined,
      address_site: values.address.city ? values.address.city[1] : undefined,
      address_street: values.address.street ? values.address.street : undefined,
      prices: prices.map((i) =>
        Object.assign(i, {
          deadline: i.deadline
            ? moment(i.deadline).format(dateFormat)
            : undefined,
        })
      ),
      extra: JSON.stringify(values.extra),
    };
    updateSession.mutateAsync({ id: session.id, data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"場次"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateSession.isLoading}
      children={
        <SessionForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditSessionButton;
