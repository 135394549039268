import { Cascader, Descriptions, Divider, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { paymentType, registrationSelectStatus } from "../../constants";
import useCourses from "../../hooks/useCourses";
import useSessions from "../../hooks/useSessions";

const { Option } = Select;
const { TextArea } = Input;

const RegistrationForm = ({
  onFinish,
  formId,
  initialValues,
  registration,
}) => {
  const { data: sessions, isSuccess: isSuccessSession } = useSessions();
  const { data: courses, isSuccess: isSuccessCourse } = useCourses();
  const extra =
    typeof JSON.parse(registration.extra) === "string"
      ? JSON.parse(JSON.parse(registration.extra))
      : JSON.parse(registration.extra);
  const ecpay =
    typeof registration.ecpay === "string"
      ? JSON.parse(registration.ecpay)
      : registration.ecpay;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (isSuccessCourse && isSuccessSession) {
      setOptions(
        courses.map((i) => {
          return {
            value: i.id,
            label: i.name,
            children: sessions
              .filter((s) => s.course_id === i.id)
              .map((s) => {
                return { value: s.id, label: s.name };
              }),
          };
        })
      );
    }
  }, [isSuccessSession, isSuccessCourse, courses, sessions]);

  return (
    <>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="報名日期">
          {registration.create_date}
        </Descriptions.Item>
        <Descriptions.Item label="課程場次">
          {registration.course_name}
          {registration.session_name}
        </Descriptions.Item>
        <Descriptions.Item label="會員帳號">
          {registration.user_email}
        </Descriptions.Item>
        <Descriptions.Item label="會員">
          {registration.user_name}
        </Descriptions.Item>
        <Descriptions.Item label="費用">{registration.fee}</Descriptions.Item>
        <Descriptions.Item label="身份別">
          {registration.identity}
        </Descriptions.Item>
        <Descriptions.Item label="發票處理方式">
          {registration.invoice}
        </Descriptions.Item>
        <Descriptions.Item label="統一編號">
          {registration.invoice_name}
        </Descriptions.Item>
        <Descriptions.Item label="備註">{registration.note}</Descriptions.Item>
        <Descriptions.Item label="繳款方式">
          {registration.payment_type}
        </Descriptions.Item>
      </Descriptions>
      {extra.length > 0 ? (
        <>
          <Divider></Divider>
          <div style={{ marginBottom: 12 }}>額外報名選項</div>
          <Descriptions column={1} bordered>
            {extra.map((i) => (
              <Descriptions.Item label={i["name"]}>
                {i["value"]}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </>
      ) : null}
      {ecpay ? (
        <>
          <Divider />
          <Descriptions column={1} bordered>
            <Descriptions.Item label="線上交易編號">
              {ecpay.merchant_trade_no}
            </Descriptions.Item>
            <Descriptions.Item label="綠界回傳交易編號">
              {ecpay.trade_no}
            </Descriptions.Item>
            <Descriptions.Item label="綠界交易日期">
              {ecpay.merchant_trade_date}
            </Descriptions.Item>
            <Descriptions.Item label="綠界交易金額">
              {ecpay.trade_amt}
            </Descriptions.Item>
            <Descriptions.Item label="綠界回傳交易訊息">
              {ecpay.rtn_msg}
            </Descriptions.Item>
            <Descriptions.Item label="綠界超商或ATM繳費截止日">
              {ecpay.expire_date}
            </Descriptions.Item>
            <Descriptions.Item label="綠界超商繳費號碼">
              {ecpay.payment_no}
            </Descriptions.Item>
            <Descriptions.Item label="綠界ATM繳費銀行代碼">
              {ecpay.bank_code}
            </Descriptions.Item>
            <Descriptions.Item label="綠界ATM繳費號碼">
              {ecpay.v_account}
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : null}
      <Divider />
      <Form
        id={formId}
        preserve={false}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        {registration.payment_type === paymentType.in_person ? (
          <Form.Item label="狀態" name="status">
            <Select
              disabled={
                registration.status === registrationSelectStatus.complete
              }
            >
              {Object.values(registrationSelectStatus).map((i) => (
                <Option value={i} key={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item label="轉場" name="new_session_id">
          <Cascader options={options} />
        </Form.Item>
        <Form.Item label="管理員通知" name="manager_note">
          <TextArea rows={4}></TextArea>
        </Form.Item>
      </Form>
    </>
  );
};

export default RegistrationForm;
