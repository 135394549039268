import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";
import { dateFormat } from "../utils";

const encode = encodeURIComponent;

export default function useSearchRegistrations(queries) {
  const { token } = useContext(AuthContext);

  let arg = [];

  if (queries) {
    if (queries["course_id"] !== "all") {
      arg.push(`course_id=${queries["course_id"]}`);
    }
    if (queries["session_id"] !== "all") {
      arg.push(`session_id=${encode(queries["session_id"])}`);
    }
    if (queries["create_date_range"]) {
      const start_date = queries["create_date_range"][0].format(dateFormat);
      const end_date = queries["create_date_range"][1].format(dateFormat);
      arg.push(
        `create_start_date=${encode(start_date)}&create_end_date=${encode(
          end_date
        )}`
      );
    }
  }
  const query_string = arg.join("&");

  return useQuery(
    ["registrations", token],
    () => api.Registration.search({ query_string, token }),
    { enabled: !!queries }
  );
}
