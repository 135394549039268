import React, { useState } from "react";
import { useFaq } from "../../hooks/useFaq";
import AddButton from "../AddButton";
import FaqForm from "./FaqForm";

const AddFaqButton = () => {
  const { createFaq } = useFaq();
  const [visible, setVisible] = useState(false);
  const formId = "add_faq";
  const initialValues = {
    question: "",
    answer: "",
    show: false,
  };

  const onFinish = (values) => {
    createFaq.mutateAsync({ data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <AddButton
      name={"問答"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createFaq.isLoading}
      children={
        <FaqForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default AddFaqButton;
