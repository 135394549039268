import { Tabs } from "antd";
import AnnualInvoiceTab from "../components/invoice/AnnualInvoiceTab";
import EAnnualInvoiceTab from "../components/invoice/EAnnualInvoiceTab";
import InvoiceTab from "../components/invoice/InvoiceTab";

const Invoice = () => {
  return (
    <>
      <Tabs>
        <Tabs.TabPane tab="單筆收據" key="every_time">
          <InvoiceTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="年度紙本" key="annual">
          <AnnualInvoiceTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="年度電子（自然人憑證）" key="e_annual">
          <EAnnualInvoiceTab />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default Invoice;
