import { Space } from "antd";
import AddProjectButton from "../components/project/AddProjectButton";
import ProjectTable from "../components/project/ProjectTable";

const Project = () => {
  return (
    <>
      <Space>
        <AddProjectButton />
      </Space>
      <ProjectTable />
    </>
  );
};

export default Project;
