import React, { createContext, useState } from "react";
import api from "../api";

const TOKEN_KEY = "jwt";
const EMAIL_KEY = "email";

export const AuthContext = createContext({
  email: undefined,
  token: undefined,
  login: undefined,
  logout: undefined,
  error: undefined,
  loading: false,
});

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    window.localStorage.getItem(TOKEN_KEY)
      ? window.localStorage.getItem(TOKEN_KEY)
      : window.sessionStorage.getItem(TOKEN_KEY)
      ? window.sessionStorage.getItem(TOKEN_KEY)
      : undefined
  );
  const [email, setEmail] = useState(window.localStorage.getItem(EMAIL_KEY));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  return (
    <AuthContext.Provider
      value={{
        token: token,
        email: email,
        error: error,
        loading: loading,
        logout: () => {
          window.localStorage.removeItem(TOKEN_KEY);
          window.sessionStorage.removeItem(TOKEN_KEY);
          window.localStorage.removeItem(EMAIL_KEY);
          setToken(undefined);
          setEmail(undefined);
        },
        login: async (email, password, remember) => {
          setLoading(true);
          setError(undefined);
          return api.Login.login(email, password)
            .then(({ data }) => {
              if (!data.is_staff) {
                throw new Error("你沒有登入這個網站的權限");
              }
              const token = data.access_token;
              if (remember) {
                window.localStorage.setItem(TOKEN_KEY, token);
              } else {
                window.sessionStorage.setItem(TOKEN_KEY, token);
              }
              setToken(token);
              window.localStorage.setItem(
                EMAIL_KEY,
                email.split("_____")[0].toLowerCase()
              );
              setEmail(email.split("_____")[0].toLowerCase());
            })
            .catch((err) => {
              setError(err.response ? err.response.data.detail : err.message);
            })
            .finally(() => {
              setLoading(false);
            });
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
