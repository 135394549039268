import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";
import { dateFormat } from "../utils";

const encode = encodeURIComponent;

export default function useSearchDonations(queries) {
  const { token } = useContext(AuthContext);

  let arg = [];

  if (queries) {
    if (queries["campaign_id"] !== "all") {
      arg.push(`campaign_id=${queries["campaign_id"]}`);
    }
    if (queries["campaign_official_id"]) {
      arg.push(
        `campaign_official_id=${encode(queries["campaign_official_id"])}`
      );
    }
    if (queries["invoice_id"]) {
      arg.push(`invoice_id=${encode(queries["invoice_id"])}`);
    }
    if (queries["create_date_range"]) {
      const start_date = queries["create_date_range"][0].format(dateFormat);
      const end_date = queries["create_date_range"][1].format(dateFormat);
      arg.push(
        `create_start_date=${encode(start_date)}&create_end_date=${encode(
          end_date
        )}`
      );
    }
  }
  const query_string = arg.join("&");

  return useQuery(
    ["donations", token],
    () => api.Donation.search({ query_string, token }),
    { enabled: !!queries }
  );
}
