import { Table, Tag } from "antd";
import moment from "moment";
import { status } from "../../constants";
import { dateTimeFormat, getFilterOptions, onFilter } from "../../utils";
import EditDonationButton from "./EditDonationButton";

const DonationTable = ({ donations, loading }) => {
  const data = donations.map((d, i) => {
    d.key = i;
    d.create_date = moment(d.create_date).format(dateTimeFormat);
    return d;
  });

  const campaign_official_id = getFilterOptions(data, "campaign_official_id");
  const send_invoice = getFilterOptions(data, "send_invoice");

  const columns = [
    { key: "id", dataIndex: "id", title: "No" },
    { key: "create_date", dataIndex: "create_date", title: "捐款時間" },
    {
      key: "project_name",
      title: "類別",
      dataIndex: "project_name",
    },
    {
      key: "campaign_name",
      title: "項目",
      dataIndex: "campaign_name",
    },
    {
      key: "campaign_official_id",
      title: "手動輸入勸募字號",
      dataIndex: "campaign_official_id",
      filterMultiple: true,
      filters: campaign_official_id,
      onFilter: onFilter("campaign_official_id"),
    },
    {
      key: "user",
      title: "會員",
      render: (text, record) =>
        record.user_id ? <div>{record.user_name}</div> : <div>非會員</div>,
    },
    { key: "amount", dataIndex: "amount", title: "金額" },
    {
      key: "payment_type",
      title: "付款方式",
      dataIndex: "payment_type",
    },
    { key: "donation_type", dataIndex: "donation_type", title: "捐款方式" },
    { key: "invoice_id", dataIndex: "invoice_id", title: "收據編號" },
    {
      key: "send_invoice",
      dataIndex: "send_invoice",
      title: "收據寄發方式",
      filters: send_invoice,
      onFilter: onFilter("send_invoice"),
    },
    {
      key: "status",
      title: "狀態",
      render: (text, record) => {
        let color = "red";
        switch (record.status) {
          case status.complete:
            color = "green";
            break;
          case status.waiting_for_check:
            color = "blue";
            break;
          case status.pending:
            color = "gold";
            break;
          case status.get_info_success:
            color = "blue";
            break;
          case status.get_info_fail:
            color = "red";
            break;
          default:
            color = "red";
            break;
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
      filters: Object.values(status).map((d) => {
        return { text: d, value: d };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    { key: "manager_note", dataIndex: "manager_note", title: "管理員備註" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => <EditDonationButton donation={record} />,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={loading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default DonationTable;
