import { Card, Col, Row, Statistic } from "antd";
import { useStatistic } from "../hooks/useStatistic";

const Dashboard = () => {
  const { statistic, loading } = useStatistic();
  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="會員數"
              value={statistic ? statistic["user"] : undefined}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="課程數"
              value={statistic ? statistic["course"] : undefined}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="場次數"
              value={statistic ? statistic["session"] : undefined}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="捐款項目數"
              value={statistic ? statistic["project"] : undefined}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
