import { Button, message } from "antd";
import axios from "axios";
import * as FileSaver from "file-saver";
import { useContext, useState } from "react";
import { getErrorMessage } from "../../utils";
import { AuthContext } from "../AuthContext";

const DonwloadInvoiceButton = ({ disabled, url }) => {
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onDownload = () => {
    setLoading(true);
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const data = new Blob([response.data], { type: "application/pdf" });
        FileSaver.saveAs(data);
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button onClick={onDownload} loading={loading} disabled={disabled}>
      下載
    </Button>
  );
};

export default DonwloadInvoiceButton;
