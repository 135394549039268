import { ConfigProvider } from "antd";
import zhTW from "antd/lib/locale/zh_TW";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.less";
import AuthProvider from "./components/AuthContext";
import Home from "./pages/Home.jsx";
import Login from "./pages/Login.jsx";
import Page404 from "./pages/Page404.jsx";
import Page500 from "./pages/Page500.jsx";

function App() {
  const queryClient = new QueryClient();
  return (
    <ConfigProvider locale={zhTW}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Router>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <Home {...props} />}
              />
            </Switch>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
