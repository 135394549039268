import { Space } from "antd";
import AddIdentityButton from "../components/identity/AddIdentityButton";
import IdentityTable from "../components/identity/IdentityTable";

const Identity = () => {
  return (
    <>
      <Space>
        <AddIdentityButton />
      </Space>
      <IdentityTable />
    </>
  );
};

export default Identity;
