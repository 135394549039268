import { Form, Input, Switch } from "antd";

const { TextArea } = Input;

const FaqForm = ({ onFinish, formId, initialValues }) => {
  const [form] = Form.useForm();

  return (
    <Form
      id={formId}
      form={form}
      preserve={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="問題"
        name="question"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea rows={4}></TextArea>
      </Form.Item>
      <Form.Item
        label="答案"
        name="answer"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea rows={4}></TextArea>
      </Form.Item>
      <Form.Item label="是否在前台顯示" name="show" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default FaqForm;
