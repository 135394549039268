import React, { useState } from "react";
import { useUser } from "../../hooks/useUser";
import AddButton from "../AddButton";
import AddUserForm from "./AddUserForm";

const AddUserButton = () => {
  const { createUser } = useUser();
  const [visible, setVisible] = useState(false);
  const formId = "add_user";

  const onFinish = (values) => {
    createUser.mutateAsync({ data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <AddButton
      name={"會員"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createUser.isLoading}
      children={<AddUserForm formId={formId} onFinish={onFinish} />}
    />
  );
};

export default AddUserButton;
