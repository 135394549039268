import { Table, Tag } from "antd";
import moment from "moment";
import { status } from "../../constants";
import { dateTimeFormat } from "../../utils";
import EditRegistrationButton from "./EditRegistrationButton";

const RegistrationTable = ({ registrations, loading }) => {
  const data = registrations.map((d, i) => {
    d.key = i;
    d.create_date = moment(d.create_date).format(dateTimeFormat);
    return d;
  });

  const columns = [
    { key: "id", dataIndex: "id", title: "No" },
    { key: "create_date", dataIndex: "create_date", title: "報名時間" },
    {
      key: "course",
      title: "課程名稱",
      dataIndex: "course_name",
    },
    {
      key: "session",
      title: "場次名稱",
      dataIndex: "session_name",
    },
    {
      key: "user",
      title: "報名會員",
      dataIndex: "user_name",
    },
    { key: "fee", dataIndex: "fee", title: "繳費金額" },
    {
      key: "status",
      title: "報名狀態",
      render: (text, record) => {
        let color = "red";
        switch (record.status) {
          case status.complete:
            color = "green";
            break;
          case status.waiting_for_check:
            color = "blue";
            break;
          case status.pending:
            color = "gold";
            break;
          case status.get_info_success:
            color = "blue";
            break;
          case status.get_info_fail:
            color = "red";
            break;
          default:
            color = "red";
            break;
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
      filters: Object.values(status).map((d) => {
        return { text: d, value: d };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    { key: "note", dataIndex: "note", title: "備註" },
    { key: "manager_note", dataIndex: "manager_note", title: "通知" },
    {
      key: "action",
      render: (text, record) => (
        <EditRegistrationButton registration={record} />
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={loading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default RegistrationTable;
