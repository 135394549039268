import { useEffect, useState } from "react";
import DownloadButton from "../components/DownloadButton";
import RegistrationTable from "../components/registration/RegistrationTable";
import SearchForm from "../components/registration/SearchForm";
import { registrationOptions } from "../downloadOptions";
import useSearchRegistrations from "../hooks/useSearchRegistrations";

const Registration = () => {
  const [query, setQuery] = useState(undefined);
  const { data, refetch, isSuccess, isLoading } = useSearchRegistrations(query);

  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const onFinish = (values) => {
    setQuery(values);
  };

  return (
    <>
      <SearchForm onFinish={onFinish} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <DownloadButton
          data={isSuccess ? data : []}
          disabled={isLoading}
          options={registrationOptions}
          fileName={"報名紀錄"}
        />
        <div>最多只顯示3000筆資料</div>
      </div>
      <RegistrationTable
        registrations={isSuccess ? data : []}
        loading={isLoading}
      />
    </>
  );
};

export default Registration;
