import { Col, Menu, Row, Skeleton } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import useUsers from "../../hooks/useUsers";
import DonationTable from "../donation/DonationTable";
import RegistrationTable from "../registration/RegistrationTable";
import UserInfoForm from "./UserInfoForm";
import UserLearningTable from "./UserLearningTable";
import UserSettingForm from "./UserSettingForm";

const UserDetail = () => {
  const { userUuid } = useParams();
  const { data: user, isSuccess } = useUsers({ uuid: userUuid });
  const { updateUser } = useUser();
  const [page, setPage] = useState("info");

  const handleClick = (e) => {
    setPage(e.key);
  };

  return (
    <>
      {isSuccess ? (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={8} md={6}>
              <Menu
                mode="inline"
                defaultSelectedKeys="info"
                onClick={handleClick}
              >
                <Menu.Item key="info">基本資料</Menu.Item>
                <Menu.Item key="registration">報名資料</Menu.Item>
                <Menu.Item key="learning">學習資料</Menu.Item>
                <Menu.Item key="donation">捐款資料</Menu.Item>
                <Menu.Item key="setting">系統設定</Menu.Item>
              </Menu>
            </Col>
            <Col xs={16} md={18}>
              {page === "info" ? (
                <UserInfoForm user={user} onUpdate={updateUser} />
              ) : null}
              {page === "registration" ? (
                <RegistrationTable
                  registrations={user["registrations"]}
                  loading={false}
                />
              ) : null}
              {page === "learning" ? (
                <UserLearningTable user={user} learnings={user["learnings"]} />
              ) : null}
              {page === "donation" ? (
                <DonationTable donations={user["donations"]} loading={false} />
              ) : null}
              {page === "setting" ? (
                <UserSettingForm user={user} onUpdate={updateUser} />
              ) : null}
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default UserDetail;
