export const getErrorMessage = (err) => {
  if (err.response) {
    return `${err.response.status} ${err.response.statusText}: ${err.response.data["detail"]}`;
  }
  return `${err}`;
};

export const getSessionFilterOptions = (arr) => {
  return arr
    ? [...new Set(arr.map((d) => d["course_name"] + d["session_name"]))]
        .sort()
        .map((d) => {
          return { text: d, value: d };
        })
    : [];
};

export const getFilterOptions = (arr, key) => {
  return [...new Set(arr.map((d) => d[key]))]
    .filter((d) => (d !== null) & (d !== ""))
    .sort()
    .map((d) => {
      return { text: d, value: d };
    });
};

export const onFilter = (key) => {
  return (value, record) => {
    if (record[key]) {
      return record[key] === value;
    }
  };
};

export const getDisplayAddress = (
  address_city,
  address_site,
  address_street
) => {
  if (address_site && address_city && address_street) {
    return (
      address_site.slice(0, 3) +
      address_city +
      address_site.slice(3) +
      address_street
    );
  } else {
    return "";
  }
};

export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const dateFormat = "YYYY-MM-DD";
