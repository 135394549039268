import React, { useState } from "react";
import { useIdentity } from "../../hooks/useIdentity";
import AddButton from "../AddButton";
import IdentityForm from "./IdentityForm";

const AddIdentityButton = () => {
  const { createIdentity } = useIdentity();
  const [visible, setVisible] = useState(false);
  const formId = "add_identity";
  const initialValues = { name: undefined };

  const onFinish = (values) => {
    createIdentity.mutateAsync(values).then(() => {
      setVisible(false);
    });
  };

  return (
    <AddButton
      name={"身份別"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createIdentity.isLoading}
      children={
        <IdentityForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default AddIdentityButton;
