import { Button, DatePicker, Form, Space, Table } from "antd";
import { useState } from "react";
import useAnnualInvoices from "../../hooks/useAnnualInvoices";
import DownloadInvoiceButton from "./DownloadInvoiceButton";

const AnnualInvoiceTab = () => {
  const [year, setYear] = useState(undefined);
  const { data, refetch, isLoading } = useAnnualInvoices(year);
  const API_ROOT = process.env.REACT_APP_ARK_API_ROOT;
  const url = `${API_ROOT}/v1/user/donation/download/annual_invoices`;

  const onFinish = (values) => {
    setYear(values.year.format("YYYY"));
    refetch();
  };

  const columns = [
    { key: "year", dataIndex: "year", title: "年度" },
    { key: "user_name", dataIndex: "user_name", title: "會員" },
    {
      key: "amount",
      title: "總金額",
      dataIndex: "amount",
    },
  ];

  return (
    <>
      <Form name="annual" layout={"inline"} onFinish={onFinish}>
        <Form.Item
          name="year"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker picker="year" />
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            搜尋
          </Button>
        </Space>
      </Form>
      <div style={{ marginTop: 15 }}>
        <DownloadInvoiceButton
          disabled={!(data && data.length > 0)}
          url={url}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        className="mt-3"
        loading={isLoading}
      ></Table>
    </>
  );
};

export default AnnualInvoiceTab;
