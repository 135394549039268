import { Space } from "antd";
import AddOfficeButton from "../components/office/AddOfficeButton";
import OfficeTable from "../components/office/OfficeTable";

const Office = () => {
  return (
    <>
      <Space>
        <AddOfficeButton />
      </Space>
      <OfficeTable />
    </>
  );
};

export default Office;
