import { Alert, Button, Divider, Modal } from "antd";
import { useState } from "react";
import UploadButton from "./UploadButton";

function CertificateButton({ session }) {
  const [visible, setVisible] = useState(false);
  const showModal = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} type="text" size="small">
        證書
      </Button>
      <Modal
        title={`結業證書設定`}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={false}
      >
        <Alert message="如果上傳一樣名稱的檔案會覆蓋掉原本的" type="warning" />
        <Divider />
        <UploadButton session={session} />
      </Modal>
    </>
  );
}

export default CertificateButton;
