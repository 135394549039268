import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from "antd";
import moment from "moment";
import useCampaigns from "../../hooks/useCampaigns";
import { dateFormat } from "../../utils";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SearchForm = ({ onFinish }) => {
  const [form] = Form.useForm();
  const { data: campaigns, isSuccess } = useCampaigns();

  return (
    <Form
      form={form}
      name="donation_search"
      onFinish={onFinish}
      initialValues={{
        campaign_id: "all",
        create_date_range: [moment().startOf("month"), moment().endOf("month")],
      }}
    >
      <Row gutter={24}>
        <Col span={8} key={"create_date_range"}>
          <Form.Item name={`create_date_range`} label={`捐款日期`}>
            <RangePicker
              format={dateFormat}
              ranges={{
                這個月: [moment().startOf("month"), moment().endOf("month")],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8} key={"campaign"}>
          <Form.Item name={`campaign_id`} label={`項目名稱`}>
            <Select>
              <Option value={"all"}>全部</Option>
              {isSuccess
                ? campaigns.map((i) => (
                    <Option value={i.id} key={i.id}>
                      {i.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} key={"campaign_official_id"}>
          <Form.Item name={`campaign_official_id`} label={`勸募字號`}>
            <Input></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item name={`invoice_id`} label={`收據編號`}>
            <Input></Input>
          </Form.Item>
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <Space>
            <Button type="primary" htmlType="submit">
              搜尋
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              清除
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
