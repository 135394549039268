import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import { useCampaign } from "../../hooks/useCampaign";
import useProjects from "../../hooks/useProjects";
import AddButton from "../AddButton";
import CampaignForm from "./CampaignForm";

const AddCampaignButton = () => {
  const { data: projects, isSuccess } = useProjects();
  const { createCampaign } = useCampaign();
  const [visible, setVisible] = useState(false);
  const formId = "add_campaign";
  const initialValues = {
    info: "",
    show: false,
    amount_options: [
      { amount: 300, name: "" },
      { amount: 600, name: "" },
      { amount: 1000, name: "" },
      { amount: 2000, name: "" },
    ],
    period_options: [{ period: 12 }, { period: 24 }, { period: 36 }],
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      period_options: values.period_options.map((i) => i["period"]).join("; "),
    };
    createCampaign.mutateAsync(data).then(() => {
      setVisible(false);
    });
  };

  return (
    <>
      {isSuccess && projects.length === 0 ? (
        <Tooltip title="請先到捐款類別頁面新增類別">
          <Button disabled>新增</Button>
        </Tooltip>
      ) : (
        <AddButton
          name={"項目"}
          formId={formId}
          visible={visible}
          setVisible={setVisible}
          loading={createCampaign.isLoading}
          children={
            <CampaignForm
              formId={formId}
              initialValues={initialValues}
              onFinish={onFinish}
            />
          }
        />
      )}
    </>
  );
};

export default AddCampaignButton;
