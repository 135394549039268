import {
  Button,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Select,
  Switch,
  Typography,
} from "antd";
import useIdentities from "../../hooks/useIdentities";
import { getDisplayAddress } from "../../utils";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const UserInfoForm = ({ user, onUpdate }) => {
  const { data: identities, isSuccess } = useIdentities();
  const initialValues = {
    team: user.team,
    office: user.office,
    student_id: user.student_id,
    order_newsletter: user.order_newsletter,
    note: user.note,
    identities: user.identities
      ? user.identities.split("; ").map((i) => i)
      : [],
  };
  const onFinish = (values) => {
    const data = { ...values, identities: values.identities.join("; ") };
    onUpdate.mutateAsync({ id: user.id, data: data }).then(() => {
      message.success("更新成功");
    });
  };

  return (
    <>
      <Title level={4}>基本資料</Title>
      <Divider></Divider>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="帳號">{user.email}</Descriptions.Item>
        <Descriptions.Item label="姓名">{user.name}</Descriptions.Item>
        <Descriptions.Item label="身份證字號">
          {user.official_id}
        </Descriptions.Item>
        <Descriptions.Item label="通訊地址">
          {getDisplayAddress(
            user.address_city,
            user.address_site,
            user.address_street
          )}
        </Descriptions.Item>
        <Descriptions.Item label="生日">{user.birthdate}</Descriptions.Item>
        <Descriptions.Item label="性別">{user.gender}</Descriptions.Item>
        <Descriptions.Item label="手機">{user.mobile}</Descriptions.Item>
        <Descriptions.Item label="市話">{user.home_phone}</Descriptions.Item>
        <Descriptions.Item label="公司/學校（年級）">
          {user.company}
        </Descriptions.Item>
        <Descriptions.Item label="婚姻狀態">
          {user.marital_status}
        </Descriptions.Item>
        <Descriptions.Item label="職業">{user.profession}</Descriptions.Item>
        <Descriptions.Item label="教育程度">{user.education}</Descriptions.Item>
        <Descriptions.Item label="信仰">{user.religion}</Descriptions.Item>
      </Descriptions>
      <Form
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Title level={4} style={{ marginTop: "20px" }}>
          資料設定
        </Title>
        <Divider></Divider>
        <Form.Item label="身份別" name="identities">
          <Select mode="multiple" allowClear>
            {isSuccess
              ? identities.map((i) => (
                  <Option value={i.name} key={i.id}>
                    {i.name}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item label="團隊" name="team">
          <Input></Input>
        </Form.Item>
        <Form.Item label="所屬區辦" name="office">
          <Input></Input>
        </Form.Item>
        <Form.Item label="學習護照號碼" name="student_id">
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="是否訂閱月刊"
          name="order_newsletter"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item label="備註（使用者不會看到）" name="note">
          <TextArea rows={4}></TextArea>
        </Form.Item>
        <Button key="submit" htmlType="submit" type="primary">
          存檔
        </Button>
      </Form>
    </>
  );
};

export default UserInfoForm;
