import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Skeleton,
  Typography,
} from "antd";
import { useOrganization } from "../hooks/useOrganization";

const { Title } = Typography;
const { TextArea } = Input;

function Setting() {
  const { isLoading, organization, updateOrganization } = useOrganization();

  const onFinish = (values) => {
    updateOrganization.mutateAsync({ data: values }).then(() => {
      message.success("更改成功");
    });
  };

  return (
    <>
      <Title level={4}>系統設定</Title>
      <Divider></Divider>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Form
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={organization}
        >
          <Card style={{ marginBottom: 10 }}>
            <Form.Item label="機構正式名稱" name="full_name">
              <Input></Input>
            </Form.Item>
            <Form.Item label="統一編號" name="official_id">
              <Input></Input>
            </Form.Item>
            <Form.Item label="專案核准文號" name="tax_id">
              <Input></Input>
            </Form.Item>
            <Form.Item label="捐款感謝信內容" name="donation_email_content">
              <TextArea rows={5}></TextArea>
            </Form.Item>
          </Card>
          <Card style={{ marginBottom: 10 }}>
            <Form.Item label="郵局劃撥戶名" name="post_name">
              <Input></Input>
            </Form.Item>
            <Form.Item label="郵局劃撥帳號" name="post_account">
              <Input></Input>
            </Form.Item>
          </Card>
          <Card style={{ marginBottom: 10 }}>
            <Form.Item label="受款行" name="beneficiary_bank">
              <Input></Input>
            </Form.Item>
            <Form.Item label="受款行地址" name="beneficiary_bank_add">
              <Input></Input>
            </Form.Item>
            <Form.Item label="受款行通匯代碼SWIFT CODE" name="swift_code">
              <Input></Input>
            </Form.Item>
            <Form.Item label="受款人戶名" name="beneficiary_name">
              <Input></Input>
            </Form.Item>
            <Form.Item label="受款人帳號" name="beneficiary_account">
              <Input></Input>
            </Form.Item>
            <Form.Item label="受款人地址" name="beneficiary_add">
              <Input></Input>
            </Form.Item>
            <Form.Item label="受款人電話" name="beneficiary_tel">
              <Input></Input>
            </Form.Item>
          </Card>
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            style={{ marginTop: 6 }}
          >
            更改
          </Button>
        </Form>
      )}
    </>
  );
}

export default Setting;
