import { Space, Table } from "antd";
import { useProject } from "../../hooks/useProject";
import useProjects from "../../hooks/useProjects";
import DeleteButton from "../DeleteButton";
import EditProjectButton from "./EditProjectButton";

const ProjectTable = () => {
  const { data: projects, isLoading, isSuccess } = useProjects();
  const { deleteProject } = useProject();

  const columns = [
    { key: "id", dataIndex: "id", title: "類別編號" },
    { key: "name", dataIndex: "name", title: "類別名稱" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <Space>
          <EditProjectButton project={record} />
          <DeleteButton
            title={`你確定要刪除「${record.name}」? 所有相關的捐款項目與捐款紀錄都會一起刪除`}
            onDelete={() => {
              deleteProject.mutate(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const data =
    isSuccess &&
    projects.map((d, i) => {
      d.key = i;
      return d;
    });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={isLoading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default ProjectTable;
