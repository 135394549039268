import moment from "moment";
import React, { useState } from "react";
import { useLearning } from "../../hooks/useLearning";
import { dateFormat } from "../../utils";
import EditButton from "../EditButton";
import UserLearningForm from "./UserLearningForm";

const EditUserLearningButton = ({ learning }) => {
  const { updateLearning } = useLearning();
  const [visible, setVisible] = useState(false);
  const formId = `edit_learning_${learning.id}`;

  const initialValues = {
    ...learning,
    finished_date: learning.finished_date
      ? moment(learning.finished_date)
      : undefined,
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      finished_date: values.finished_date
        ? values.finished_date.format(dateFormat)
        : undefined,
    };

    updateLearning.mutateAsync({ id: learning.id, data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"課程紀錄"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateLearning.isLoading}
      children={
        <UserLearningForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
          learning={learning}
        />
      }
    />
  );
};

export default EditUserLearningButton;
