import { Form, Input, Radio, Select, Space } from "antd";
import { sendInvoiceOptions } from "../../constants";

const { Option } = Select;

const InvoiceFormItem = ({ name }) => {
  return (
    <>
      <Form.Item
        label="收據寄發方式"
        name={[name, "choice"]}
        extra="響應環保，年度寄發以隔年4月郵寄以便報稅"
        style={{ marginBottom: 10 }}
        rules={[
          {
            required: true,
            message: "請選擇",
          },
        ]}
      >
        <Select>
          {Object.values(sendInvoiceOptions).map((i) => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[name].choice !== currentValues[name].choice
        }
      >
        {({ getFieldValue }) =>
          getFieldValue(name).choice === sendInvoiceOptions.annual ||
          getFieldValue(name).choice === sendInvoiceOptions.everytime ? (
            <Radio.Group style={{ marginBottom: 20 }} defaultValue={"同"}>
              <Space direction="vertical">
                <Radio value={"同"} key={"同"}>
                  同通訊地址
                </Radio>
                <Radio value={"另"} key={"另"}>
                  另填收據地址
                  <Form.Item name={[name, "address"]} noStyle>
                    <Input />
                  </Form.Item>
                </Radio>
              </Space>
            </Radio.Group>
          ) : null
        }
      </Form.Item>
    </>
  );
};

export default InvoiceFormItem;
