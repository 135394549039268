import { Table, Button } from "antd";
import { Link } from "react-router-dom";

const UserTable = ({ users, loading }) => {
  const columns = [
    { key: "id", dataIndex: "uuid", title: "會員編號" },
    { key: "name", dataIndex: "name", title: "姓名" },
    { key: "email", dataIndex: "email", title: "Email" },
    { key: "mobile", dataIndex: "mobile", title: "手機" },
    { key: "tw_id", dataIndex: "tw_id", title: "身份證字號" },
    { key: "gender", dataIndex: "gender", title: "性別" },
    {
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <Link to={`/user/${record.uuid}`}>
          <Button size="small" type="text">
            詳情
          </Button>
        </Link>
      ),
    },
  ];

  const data = users.map((d, i) => {
    d.key = i;
    return d;
  });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={loading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default UserTable;
