import { UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Divider } from "antd";
import { useEffect, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import DownloadButton from "../components/DownloadButton";
import AddUserButton from "../components/user/AddUserButton";
import SearchForm from "../components/user/SearchForm";
import UserDetail from "../components/user/UserDetail";
import UserTable from "../components/user/UserTable";
import { userOptions } from "../downloadOptions";
import useSearchUsers from "../hooks/useSearchUsers";

const User = () => {
  const [query, setQuery] = useState(undefined);
  const { data: users, isLoading, isSuccess, refetch } = useSearchUsers(query);
  const match = useRouteMatch();
  const location = useLocation();
  const userUuid = location.pathname.split("/").slice(-1)[0];

  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const onFinish = (values) => {
    setQuery(values);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <UserOutlined />
            <Link to={`${match.url}`}>會員</Link>
          </Breadcrumb.Item>
          {userUuid !== "user" ? (
            <Breadcrumb.Item>
              <Link to={`${match.url}`}>會員編號 {userUuid} </Link>
            </Breadcrumb.Item>
          ) : null}
        </Breadcrumb>
        <AddUserButton />
      </div>
      <Divider />
      <Switch>
        <Route path={`${match.path}/:userUuid`}>
          <UserDetail />
        </Route>
        <Route path={match.path}>
          <SearchForm onFinish={onFinish} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <DownloadButton
              data={isSuccess ? users : []}
              options={userOptions}
              fileName={"會員"}
            />
            <div>最多只顯示3000筆資料</div>
          </div>
          <UserTable users={isSuccess ? users : []} loading={isLoading} />
        </Route>
      </Switch>
    </>
  );
};

export default User;
