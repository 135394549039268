const sessionOptions = [
  { label: "場次編號", value: "id" },
  { label: "場次名稱", value: "name" },
  { label: "課程編號", value: "course_id" },
  { label: "課程名稱", value: "course_name" },
  { label: "開課單位", value: "office" },
  { label: "開始報名日期", value: "register_start_date" },
  { label: "結束報名日期", value: "register_end_date" },
  { label: "報名優惠截止日期", value: "discount_deadline" },
  { label: "課程日期", value: "course_date" },
  { label: "地址縣市", value: "address_city" },
  { label: "地址區域", value: "address_site" },
  { label: "地址路名", value: "address_street" },
  { label: "地點", value: "place" },
  { label: "是否開放報名", value: "open" },
  { label: "是否在前台顯示", value: "show" },
  { label: "報名人數上限", value: "max_count" },
  { label: "目前報名人數", value: "count" },
  { label: "是否顯示目前報名人數", value: "show_count" },
  { label: "額外報名選項", value: "extra" },
];

const userOptions = [
  { label: "會員編號", value: "uuid" },
  { label: "帳號", value: "email" },
  { label: "姓名", value: "name" },
  { label: "身份證字號", value: "official_id" },
  { label: "通訊地址縣市", value: "address_city" },
  { label: "通訊地址區域", value: "address_site" },
  { label: "通訊地址路名", value: "address_street" },
  { label: "生日", value: "birthdate" },
  { label: "性別", value: "gender" },
  { label: "市話", value: "home_phone" },
  { label: "手機", value: "mobile" },
  { label: "公司/學校", value: "company" },
  { label: "婚姻狀態", value: "marital_status" },
  { label: "職業", value: "profession" },
  { label: "教育程度", value: "education" },
  { label: "信仰", value: "religion" },
  { label: "團隊", value: "team" },
  { label: "所屬區辦", value: "office" },
  { label: "學習護照號碼", value: "student_id" },
  { label: "是否是志工", value: "is_volunteer" },
  { label: "是否訂閱月刊", value: "order_newsletter" },
  { label: "備註", value: "note" },
];

const registrationOptions = [
  { label: "報名日期", value: "create_date" },
  { label: "課程名稱", value: "course_name" },
  { label: "場次名稱", value: "session_name" },
  { label: "費用", value: "fee" },
  { label: "狀態", value: "status" },
  { label: "身份別", value: "identity" },
  { label: "發票處理方式", value: "invoice" },
  { label: "統一編號", value: "invoice_name" },
  { label: "備註", value: "note" },
  { label: "管理員備註", value: "manager_note" },
  { label: "是否是線下繳款", value: "is_pay_offline" },
  { label: "綠界交易資訊", value: "ecpay" },
  { label: "額外報名選項", value: "extra" },
];

const donationOptions = [
  { label: "捐款日期", value: "create_date" },
  { label: "類別名稱", value: "project_name" },
  { label: "項目名稱", value: "campaign_name" },
  { label: "項目專案核准文號", value: "campaign_approval_id" },
  { label: "手動輸入勸募字號", value: "campaign_official_id" },
  { label: "會員", value: "user_name" },
  { label: "金額", value: "amount" },
  { label: "捐款方式", value: "donation_type" },
  { label: "捐款期數", value: "period" },
  { label: "狀態", value: "status" },
  { label: "收據寄發方式", value: "send_invoice" },
  { label: "收據寄發地址", value: "send_invoice_address" },
  { label: "收據編號", value: "invoice_id" },
  { label: "芳名錄", value: "show_name" },
  { label: "備註", value: "note" },
  { label: "管理員備註", value: "manager_note" },
  { label: "是否是線下繳款", value: "is_pay_offline" },
  { label: "線下繳款日期", value: "offline_pay_date" },
  { label: "線下繳款帳號後五碼", value: "offline_pay_account" },
  { label: "綠界交易資訊", value: "ecpay" },
];

registrationOptions.splice(
  2,
  0,
  ...userOptions.map((i) =>
    Object.assign({ ...i, value: "user_" + i.value }, {})
  )
);

const eAnnualdonationOptions = [
  { label: "捐贈年度", value: "year" },
  { label: "捐贈者身分證統一編號", value: "user_official_id" },
  { label: "捐贈者姓名", value: "user_name" },
  { label: "捐款金額", value: "amount" },
  { label: "捐贈別", value: "organization_donation_type" },
  { label: "受捐贈單位統一編號", value: "organization_official_id" },
  { label: "受捐贈者姓名", value: "organization_full_name" },
  { label: "專案核准文號", value: "organization_tax_id" },
];

export {
  donationOptions,
  eAnnualdonationOptions,
  registrationOptions,
  sessionOptions,
  userOptions
};
