import React, { useState } from "react";
import { useFaq } from "../../hooks/useFaq";
import EditButton from "../EditButton";
import FaqForm from "./FaqForm";

const EditFaqButton = ({ faq }) => {
  const { updateFaq } = useFaq();
  const [visible, setVisible] = useState(false);
  const formId = "edit_faq";
  const initialValues = faq;

  const onFinish = (values) => {
    updateFaq.mutateAsync({ id: faq.id, data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"問答"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateFaq.isLoading}
      children={
        <FaqForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditFaqButton;
