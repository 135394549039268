import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";
import { sendInvoiceOptions } from "../constants";

export default function useEAnnualInvoice(year) {
  const { token } = useContext(AuthContext);
  const sendInvoiceType = sendInvoiceOptions.e_annual;

  return useQuery(
    ["e_annual_invoice", token],
    () => api.Donation.getAnnualInvoices({ year, sendInvoiceType, token }),
    { enabled: !!year }
  );
}
