import { Form, Input, Select, Switch } from "antd";
import useProjects from "../../hooks/useProjects";
import AmountOptionFormItem from "./AmountOptionFormItem";
import PeriodOptionFormItem from "./PeriodOptionFormItem";

const { TextArea } = Input;
const { Option } = Select;

const CampaignForm = ({ onFinish, formId, initialValues }) => {
  const { data: projects, isSuccess: isSuccessProject } = useProjects();

  return (
    <Form
      id={formId}
      preserve={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="捐款類別"
        name="project_id"
        rules={[
          {
            required: true,
            message: "請選擇",
          },
        ]}
      >
        <Select>
          {isSuccessProject &&
            projects.map((i) => (
              <Option value={i.id} key={i.name}>
                {i.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="項目名稱"
        name="name"
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="專案核准文號" name="approval_id">
        <Input></Input>
      </Form.Item>
      <Form.Item label="項目說明（HTML）" name="info">
        <TextArea rows={4}></TextArea>
      </Form.Item>
      <Form.Item label="捐款說明（HTML）" name="donation_info">
        <TextArea></TextArea>
      </Form.Item>
      <AmountOptionFormItem />
      <PeriodOptionFormItem />
      <Form.Item label="是否在前台顯示" name="show" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default CampaignForm;
