import { Tag } from "antd";
import { dateFormat } from "../../utils";

const PriceTags = ({ data }) => {
  return (
    <div>
      {data.map((i) => (
        <Tag key={i.name}>
          {i.name + ": " + i.amount}
          {i.identity === "無" ? "" : "(" + i.identity + ")"}
          {i.deadline && typeof i.deadline !== "string"
            ? i.deadline.format(dateFormat)
            : ""}
        </Tag>
      ))}
    </div>
  );
};

export default PriceTags;
