import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Space } from "antd";

const AmountOptionFormItem = () => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>金額選項</div>
      <Form.List
        name="amount_options"
        rules={[
          {
            validator: async (_, amount_options) => {
              if (!amount_options || amount_options.length < 1) {
                return Promise.reject(new Error("請至少填寫一個選項"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                <Form.Item
                  {...field}
                  label="金額"
                  name={[field.name, "amount"]}
                  fieldKey={[field.fieldKey, "amount"]}
                  rules={[{ required: true, message: "請填寫金額" }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="金額說明"
                  name={[field.name, "name"]}
                  fieldKey={[field.fieldKey, "name"]}
                >
                  <Input />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.ErrorList errors={errors} />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                新增選項
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default AmountOptionFormItem;
