import { Button, Card, DatePicker, Form } from "antd";
import { useEffect, useState } from "react";
import { eAnnualdonationOptions } from "../../downloadOptions";
import useEAnnualInvoice from "../../hooks/useEAnnualInvoice";
import ExportToExcel from "../ExportToExcel";

const EAnnualInvoiceTab = () => {
  const [year, setYear] = useState(undefined);
  const { data, refetch, isSuccess } = useEAnnualInvoice(year);
  const fileName = "自然人憑證匯出";

  useEffect(() => {
    if (year) {
      refetch();
    }
  }, [year, refetch]);

  const downloadColumns = eAnnualdonationOptions.reduce(
    (obj, item) => Object.assign(obj, { [item.value]: item.label }),
    {}
  );

  const onFinish = (values) => {
    setYear(values.year.format("YYYY"));
  };

  return (
    <>
      <Form name="e_annual" layout={"inline"} onFinish={onFinish}>
        <Form.Item
          name="year"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker picker="year" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          搜尋
        </Button>
      </Form>
      {isSuccess ? (
        <Card
          title={`${year} 有 ${data.length} 筆資料`}
          style={{
            marginTop: 20,
          }}
        >
          <ExportToExcel
            key="export"
            disabled={data.length === 0}
            apiData={data}
            fileName={fileName}
            columnMap={downloadColumns}
          />
        </Card>
      ) : null}
    </>
  );
};

export default EAnnualInvoiceTab;
