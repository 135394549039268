import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export default function useInvoices({ startDate, endDate }) {
  const { token } = useContext(AuthContext);

  return useQuery(
    ["invoices", token],
    () =>
      api.Donation.getInvoices({
        startDate,
        endDate,
        token,
      }),
    { enabled: !!startDate && !!endDate }
  );
}
