import { Button, Divider, Form, Switch, Typography } from "antd";

const { Title } = Typography;

const UserSettingForm = ({ user, onUpdate }) => {
  const onFinish = (values) => {
    onUpdate.mutate({ id: user.id, data: values });
  };

  return (
    <>
      <Title level={4}>系統設定</Title>
      <Divider></Divider>
      <Form
        layout={"vertical"}
        initialValues={{
          is_active: user.is_active,
          is_staff: user.is_staff,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="是否可以登入前台網站"
          name="is_active"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="是否可以登入管理後台網站"
          name="is_staff"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Button key="submit" htmlType="submit" type="primary">
          存檔
        </Button>
      </Form>
    </>
  );
};

export default UserSettingForm;
