import { Space } from "antd";
import AddProgramButton from "../components/program/AddProgramButton";
import ProgramTable from "../components/program/ProgramTable";

const Program = () => {
  return (
    <>
      <Space>
        <AddProgramButton />
      </Space>
      <ProgramTable />
    </>
  );
};

export default Program;
