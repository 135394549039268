import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useDonation = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);
  const create = useMutation(({ data }) => api.Donation.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("donations");
      queryClient.invalidateQueries("download_donations");
      queryClient.invalidateQueries("user");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.Donation.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("donations");
        queryClient.invalidateQueries("download_donations");
        queryClient.invalidateQueries("user");
      },
    }
  );

  return {
    createDonation: create,
    updateDonation: update,
  };
};
