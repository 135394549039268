import {
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
} from "antd";
import useCourses from "../../hooks/useCourses";
import useOffices from "../../hooks/useOffices";
import AddressFormItem from "../AddressFormItem";
import ExtraFormItem from "./ExtraFromItem";
import PriceFormItem from "./PriceFormItem";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const SessionForm = ({ onFinish, formId, initialValues }) => {
  const { data: courses, isSuccess: isSuccessCourse } = useCourses();
  const { data: offices, isSuccess: isSuccessOffice } = useOffices();

  return (
    <Form
      id={formId}
      preserve={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="課程"
        name="course_id"
        rules={[
          {
            required: true,
            message: "請選擇",
          },
        ]}
      >
        <Select>
          {isSuccessCourse &&
            courses.map((i) => (
              <Option value={i.id} key={i.name}>
                {i.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="場次名稱"
        name="name"
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label="開課單位" name="office_id">
        <Select>
          {isSuccessOffice &&
            offices.map((i) => (
              <Option value={i.id} key={i.name}>
                {i.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="報名說明（HTML）" name="registration_info">
        <TextArea></TextArea>
      </Form.Item>
      <Form.Item
        label="課程日期"
        name="course_date"
        help={"顯示在前台報名說明"}
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="課程開始結束時間"
        name="course_date_range"
        help={"顯示在學習記錄上"}
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item label="報名時間" name="register_date_range" required={true}>
        <RangePicker />
      </Form.Item>
      <PriceFormItem />
      <AddressFormItem required={false} />
      <Form.Item label="地點" name="place">
        <Input></Input>
      </Form.Item>
      <Form.Item label="聯絡方式" name="contact">
        <Input></Input>
      </Form.Item>
      <Form.Item label="是否在前台顯示" name="show" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="是否開放報名" name="open" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="報名人數限制" name="max_count">
        <InputNumber min={0}></InputNumber>
      </Form.Item>
      <Form.Item label="現在報名人數" name="count">
        <InputNumber min={0}></InputNumber>
      </Form.Item>
      <Form.Item
        label="是否顯示目前報名人數"
        name="show_count"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="報名通知 email 內容"
        name="registration_email_content"
        rules={[
          {
            required: true,
            message: "請填寫",
          },
        ]}
      >
        <TextArea rows={5}></TextArea>
      </Form.Item>
      <Divider />
      <ExtraFormItem />
      <Divider />
      <Form.Item
        label="是否提供線上結業證書"
        name="has_certificate"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item label="結業證書上傳檔案名稱" name="certificate_name">
          <Input disabled={true}/>
        </Form.Item>
      <Form.Item label="課程時數" name="total_hours" help={"顯示在結業證書上"}>
        <InputNumber min={0} />
      </Form.Item>
    </Form>
  );
};

export default SessionForm;
