import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../components/AuthContext";
import api from "../api";

export const useCourse = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const create = useMutation((data) => api.Course.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.Course.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("courses");
      },
    }
  );

  const del = useMutation((id) => api.Course.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
    },
  });

  return {
    createCourse: create,
    updateCourse: update,
    deleteCourse: del,
  };
};
