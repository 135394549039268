import { Button, Col, DatePicker, Form, Row, Select, Space } from "antd";
import moment from "moment";
import { useState } from "react";
import useCourses from "../../hooks/useCourses";
import useSessions from "../../hooks/useSessions";
import { dateFormat } from "../../utils";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SearchForm = ({ onFinish }) => {
  const [course, setCourse] = useState(undefined);
  const [form] = Form.useForm();
  const { data: courses, isSuccess: isCourseSuccess } = useCourses();
  const { data: sessions, isSuccess: isSessionSuccess } = useSessions();

  const onSelectCourse = (value) => {
    setCourse(value);
  };

  return (
    <Form
      form={form}
      name="registration_search"
      onFinish={onFinish}
      initialValues={{
        course_id: "all",
        session_id: "all",
        create_date_range: [moment().startOf("month"), moment().endOf("month")],
      }}
    >
      <Row gutter={24}>
        <Col span={8} key={"create_date_range"}>
          <Form.Item name={`create_date_range`} label={`報名日期`}>
            <RangePicker
              format={dateFormat}
              ranges={{
                這個月: [moment().startOf("month"), moment().endOf("month")],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8} key={"course"}>
          <Form.Item name={`course_id`} label={`課程名稱`}>
            <Select onSelect={onSelectCourse}>
              <Option value={"all"}>全部</Option>
              {isCourseSuccess
                ? courses.map((i) => (
                    <Option value={i.id} key={i.id}>
                      {i.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} key={"session"}>
          <Form.Item name={`session_id`} label={`場次名稱`}>
            <Select>
              <Option value={"all"}>全部</Option>
              {isCourseSuccess && isSessionSuccess && course
                ? sessions
                    .filter((i) => i.course_id === course)
                    .map((i) => (
                      <Option value={i.id} key={i.id}>
                        {i.name}
                      </Option>
                    ))
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Space>
            <Button type="primary" htmlType="submit">
              搜尋
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              清除
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
