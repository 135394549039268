import { Cascader, Form, Input } from "antd";
import React from "react";
import { cityOptions, siteOptions } from "../address";

const AddressFormItem = ({ required }) => {
  const options = cityOptions.map((i) => {
    return {
      label: i,
      value: i,
      children: siteOptions[i].map((j) => {
        return { label: j["district"], value: j["zip"] + j["district"] };
      }),
    };
  });

  return (
    <Form.Item label="地址" required={required}>
      <Input.Group compact>
        <Form.Item
          name={["address", "city"]}
          noStyle
          rules={[{ required: required, message: "請選擇縣市" }]}
        >
          <Cascader
            options={options}
            placeholder="縣市"
            style={{ width: "30%" }}
          />
        </Form.Item>
        <Form.Item
          name={["address", "street"]}
          noStyle
          rules={[{ required: required, message: "請填寫路名" }]}
        >
          <Input style={{ width: "70%" }} placeholder="路名" />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};

export default AddressFormItem;
